import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DiscountIcon from "@mui/icons-material/Discount";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  Divider,
  Popover,
  Typography,
  useTheme,
  CircularProgress,
  Backdrop,
  Checkbox,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CouponCardWidget from "./CouponCardWidget";
import dayjs from "dayjs";
import { apiUrl } from "config";
import { cloneDeep } from "lodash";
import { razorPayScriptUrl } from "config";
import Logo from "../../assests/logo/Vybez_Logo.png";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { seo } from "utilites/utlis";

const CheckoutWidget = ({ checkoutData }) => {
  const [isAttending, setIsAttending] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [paramTransactionId, setTransaction] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const loggedInUser = useSelector((state) => state.user);
  const event = useSelector((state) => state.event);
  console.log("event data", event);
  const purchasedTickets = useSelector((state) => state.purchasedTickets);
  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;

  const [couponCode, setCouponCode] = useState("");
  const [couponPrice, setCouponPrice] = useState(0);
  const [basePrice, setBasePrize] = useState(0);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [afterDiscountPrice, setAfterDiscountPrice] = useState(0);
  const [gstPrice, setGstPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);

  const taxePercent = 1.18;
  const params = useParams();
  const [fees, setFees] = useState(0);
  const [privacyPolicy, setPrivacyPolicyChecked] = useState(false);
  const [termsAndCondn, setTermsAndCondnChecked] = useState(false);
  const [appliedCouponId, setAppliedCouponId] = useState(null);
  const sampleCoupons = [
    // {
    //   code: "COUPON1",
    //   description: "20% off on all products",
    //   expiresOn: "2023-12-31",
    // },
    // {
    //   code: "COUPON2",
    //   description: "Free shipping on orders over $50",
    //   expiresOn: "2023-12-31",
    // },
    // {
    //   code: "COUPON3",
    //   description: "$10 off on your first order",
    //   expiresOn: "2023-12-31",
    // },
  ];
  seo({
    title: `Checkout: ${event.eventName}`,
    metaDescription: `Checkout: ${event.eventName}`,
  });

  useEffect(() => {
    if (event) {
      if (event.eventType == "GUESTLIST") {
        setFees(50);
      } else {
        setFees(50);
      }
    }
  }, []);
  const handleChangePP = (event) => {
    setPrivacyPolicyChecked(event.target.checked);
  };
  const handleChangeTC = (event) => {
    setTermsAndCondnChecked(event.target.checked);
  };
  const handlePopupOpen = () => {
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const handlePopupApply = () => {
    handlePopupClose();
  };

  const getTotalTickets = () => {
    let tickets = cloneDeep(purchasedTickets);
    if (tickets) {
      let ticketStr = "";
      tickets.map((ele, index) => {
        ticketStr =
          ticketStr +
          ele.name +
          ": " +
          ele.ticketPurchaseCount +
          (index == tickets.length - 1 ? "" : ",");
      });
      return ticketStr;
    } else {
      return "";
    }
  };

  const getTotalTicketPrice = () => {
    let tickets = cloneDeep(purchasedTickets);
    if (tickets && tickets.length > 0) {
      const totalTicketsPrice = tickets.reduce((total, ele) => {
        const ticketPrice = parseInt(ele.ticketPrice || 0, 10);
        const ticketCount = parseInt(ele.ticketPurchaseCount || 0, 10);
        return total + ticketPrice * ticketCount;
      }, 0);
      setBasePrize(totalTicketsPrice);
    } else {
      setBasePrize(0);
    }
  };

  const applyCoupon = (coupon, id) => {
    setCouponCode(coupon.couponCode);
    setAppliedCouponId(id);
    const couponValue = coupon.couponValue;

    if (coupon.discountType === "Amount") {
      setCouponPrice(couponValue);
    } else if (coupon.discountType === "Percentage") {
      const couponDiscount = (basePrice * couponValue) / 100;
      setCouponPrice(couponDiscount);
    } else {
      setCouponPrice(0);
    }
  };

  const removeCoupon = () => {
    setCouponCode("");
    setCouponPrice(0);
    setAppliedCouponId(null);
  };

  useEffect(() => {
    // Calculate discounted price
    const afterDiscount = basePrice - couponPrice;
    setAfterDiscountPrice(parseFloat(afterDiscount).toFixed(2));

    const calculatedDiscount = basePrice - afterDiscount + 50;
    setDiscountPrice(parseFloat(calculatedDiscount).toFixed(2));

    // Calculate GST
    const gstAmount = afterDiscount * 0.18;
    setGstPrice(parseFloat(gstAmount).toFixed(2));

    // Calculate total price
    const price = afterDiscount + gstAmount;
    setFinalPrice(parseFloat(price).toFixed(2));
  }, [basePrice, couponPrice]);

  useEffect(() => {
    getTotalTicketPrice();
  }, [purchasedTickets]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const initiatePayment = async () => {
    setLoading(true);
    let totalPrice = finalPrice;
    let discount = discountPrice;
    let tickets = cloneDeep(purchasedTickets);
    let taxAmt = gstPrice;
    let noOfTickets = 0;
    if (tickets) {
      let totalTicketsPrice = 0;
      tickets.map((ele, index) => {
        totalTicketsPrice =
          totalTicketsPrice +
          parseInt(ele.ticketPurchaseCount) *
            parseInt(ele.ticketPrice ? ele.ticketPrice : 0);
        noOfTickets = noOfTickets + parseInt(ele.ticketPurchaseCount);
      });
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      setLoading(false);
      return;
    }

    const response = await fetch(`${apiUrl}/checkout/initiate-order`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        totalPrice: totalPrice,
        taxes: taxAmt,
        fees: fees,
        discount: discount,
        eventId: event._id,
        userId: loggedInUserId,
        purchasedTickets: purchasedTickets,
        name: loggedInUser.fullName,
        email: loggedInUser.email,
        contact: loggedInUser.phone,
        noOfTicketsPurchased: noOfTickets,
        eventType: event.eventType,
      }),
    });
    const orderUpdate = await response.json();
    if (!orderUpdate) {
      alert("Server error. Are you online?");
      setLoading(false);
      return;
    }
    const { amount, id: order_id, currency } = orderUpdate;
    const options = {
      key: "rzp_test_S2okzzmVFjUYuJ", // Enter the Key ID generated from the Dashboard
      amount: amount?.toString(),
      currency: currency,
      name: "VybeZ",
      description: `For ticket purchase of ${event.eventName}`,
      image: "https://vybez.in/logo192Black.png",
      order_id: order_id,
      handler: async (response) => {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          eventId: event._id,
          userId: loggedInUserId,
          name: loggedInUser?.fullName ? loggedInUser?.fullName : "",
          email: loggedInUser.email,
          contact: loggedInUser.phone,
          tickets: purchasedTickets,
        };
        console.log("data payment sttaus", data, response);
        const completeOrderResp = await fetch(
          `${apiUrl}/checkout/complete-order`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data: data }),
          }
        );
        console.log("completeOrderResp", completeOrderResp);
        setLoading(false);
        navigate("/bookinghistory");
        // alert(data.razorpayOrderId);
      },
      prefill: {
        name: loggedInUser?.fullName,
        email: loggedInUser.email,
        contact: loggedInUser.phone,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#E6C100",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", (response) => {
      console.log("response", response);
      setLoading(false);
    });
    paymentObject.open();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };
  const initiatePaymentPhonePe = async () => {
    try {
      setLoading(true);
      let totalPrice = finalPrice;
      let discount = discountPrice;
      let tickets = cloneDeep(purchasedTickets);
      let taxAmt = gstPrice;
      let noOfTickets = 0;
      if (tickets) {
        let totalTicketsPrice = 0;
        tickets.map((ele, index) => {
          totalTicketsPrice =
            totalTicketsPrice +
            parseInt(ele.ticketPurchaseCount) *
              parseInt(ele.ticketPrice ? ele.ticketPrice : 0);
          noOfTickets = noOfTickets + parseInt(ele.ticketPurchaseCount);
        });
      }

      const response = await fetch(
        `${apiUrl}/checkout/initiate-order-phonepe`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            totalPrice: totalPrice,
            taxes: taxAmt,
            fees: fees,
            discount: discount,
            eventId: event._id,
            userId: loggedInUserId,
            purchasedTickets: purchasedTickets,
            name: loggedInUser?.fullName ? loggedInUser?.fullName : "",
            email: loggedInUser.email,
            contact: loggedInUser.phone,
            noOfTicketsPurchased: noOfTickets,
          }),
        }
      );
      const orderUpdate = await response.json();
      if (orderUpdate.error == false) {
        console.log("orderUpdate", orderUpdate);
        window.open(
          orderUpdate.order.redirectUrl,
          "_self",
          "popup=yes",
          "height=200,width=150"
        );
      }
    } catch (e) {
      setLoading(false);
      toast.error(`Unable to initiate transaction. Try again.`);
    }
  };
  const checkTransactionStatus = async () => {
    if (params.transactionId != undefined && params.transactionId != "") {
      if (
        params.transactionId !== "initiate" &&
        paramTransactionId != params.transactionId
      ) {
        setTransaction(params.transactionId);
        setPageLoading(true);
        const response = await fetch(
          `${apiUrl}/checkout/check-transaction-status`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              transactionId: params.transactionId,
              orderCreationId: params.transactionId,
              eventId: event._id,
              userId: loggedInUserId,
              name: loggedInUser?.fullName ? loggedInUser?.fullName : "",
              email: loggedInUser.email,
              contact: loggedInUser.phone,
              tickets: purchasedTickets,
            }),
          }
        );
        const transactionStatus = await response.json();
        if (transactionStatus.error == false) {
          toast.success("Transaction is successful. Get ready to Vybe it!", {
            toastId: params.transactionId,
          });
          navigate("/bookinghistory");
        } else {
          toast.error(
            `Transaction unsuccessful. Error: ${transactionStatus.data.message}.`
          );
        }
      }
    }
  };

  const initiateRSVP = async () => {
    try {
      setLoading(true);
      let totalPrice = finalPrice;
      let discount = discountPrice;
      let tickets = cloneDeep(purchasedTickets);
      let taxAmt = gstPrice;
      let noOfTickets = 0;
      if (tickets) {
        let totalTicketsPrice = 0;
        tickets.map((ele, index) => {
          totalTicketsPrice =
            totalTicketsPrice +
            parseInt(ele.ticketPurchaseCount) *
              parseInt(ele.ticketPrice ? ele.ticketPrice : 0);
          noOfTickets = noOfTickets + parseInt(ele.ticketPurchaseCount);
        });
      }

      const response = await fetch(`${apiUrl}/checkout/complete-order-rsvp`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          totalPrice: totalPrice,
          taxes: taxAmt,
          fees: fees,
          discount: discount,
          eventId: event._id,
          userId: loggedInUserId,
          purchasedTickets: purchasedTickets,
          name: loggedInUser?.fullName ? loggedInUser?.fullName : "",
          email: loggedInUser.email,
          contact: loggedInUser.phone,
          noOfTicketsPurchased: noOfTickets,
          eventType: event.eventType,
        }),
      });
      const orderUpdate = await response.json();
      if (orderUpdate.error == false) {
        setLoading(false);
        toast.success("Booking Successful. Get ready to Vybe it!");
        navigate("/bookinghistory");
      } else {
        toast.error("Unable to complete the booking. Try again later");
        setLoading(false);
        return;
      }
    } catch (e) {
      toast.error("Unable to complete the booking. Try again later");
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    checkTransactionStatus();
  }, [params.transactionId]);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={pageLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <WidgetWrapper mb="1rem">
        <FlexBetween justifyContent="center" alignItems="center" gap="0.75rem">
          <Box width="35%">
            <img
              width="100%"
              height="auto"
              alt="event"
              style={{ borderRadius: "0.75rem", marginTop: "0.50rem" }}
              src={`${event.picturePath}`}
            />
          </Box>
          <Box width="60%">
            <Typography variant="h4" pb="1rem" sx={{ color: primary }}>
              {event.eventName}
            </Typography>
            {/* <Typography variant="p" pb=".5rem" sx={{ color: main }}>
              {event.eventDate} - {checkoutData.eventTime}
            </Typography> */}
            <Typography variant="p" pb=".5rem" sx={{ color: main }}>
              {event.eventDate
                ? dayjs(event.eventDate).format("MMMM, DD MMM YYYY")
                : ""}
            </Typography>
            <br />
            <Typography variant="p" pb=".5rem" sx={{ color: main }}>
              {event.eventStartTime
                ? dayjs(event.eventStartTime).format("hh:mm A")
                : ""}{" "}
              to{" "}
              {event.eventStartTime
                ? dayjs(event.eventEndTime).format("hh:mm A")
                : ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: main, display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon sx={{ fontSize: 16, marginRight: "4px" }} />
              {event.location}
            </Typography>
          </Box>
        </FlexBetween>
      </WidgetWrapper>

      <WidgetWrapper mb="1rem">
        <FlexBetween justifyContent="space-between" alignItems="center">
          {/* <Typography
            variant="h6"
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DiscountIcon sx={{ fontSize: 16, marginRight: "4px" }} /> Apply
            Coupon
          </Typography> */}
          <Typography
            variant="h6"
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            You've got a discount of Rs.50
          </Typography>
        </FlexBetween>
        <Divider sx={{ my: "0.2rem" }} />
        <Typography
          variant="h6"
          color="primary"
          sx={{ display: "flex", alignItems: "center" }}
        >
          <DiscountIcon sx={{ fontSize: 16, marginRight: "4px" }} /> LAUNCH100
          to get 100% OFF on our platform fees. Applied
        </Typography>
        {/* <Button variant="text" fullWidth onClick={handlePopupOpen}>
          <FlexBetween
            justifyContent="space-between"
            alignItems="center"
            m=".25rem"
          >
            <Typography
              variant="body2"
              sx={{ display: "flex", alignItems: "center" }}
            >
              View All Coupons
            </Typography>
            <ArrowRightIcon />
          </FlexBetween>
        </Button> */}
      </WidgetWrapper>
      <WidgetWrapper mb="1rem">
        <FlexBetween justifyContent="space-between" alignItems="center">
          <Typography
            variant="h6"
            color="primary"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Available Coupons
          </Typography>
        </FlexBetween>
        <Divider sx={{ my: "0.2rem" }} />
        {event.coupons != undefined && event.coupons.length > 0 ? (
          <>
            {event.coupons.map((coupon, id) => (
              <div key={id} className="flex justify-between my-2">
                <div>
                  {coupon.couponName}
                  <Typography
                    variant="h6"
                    color="primary"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <DiscountIcon sx={{ fontSize: 16, marginRight: "4px" }} />{" "}
                    Apply {coupon.couponCode} to get{" "}
                    {coupon.discountType === "Amount" && "₹"}{" "}
                    {coupon.couponValue}
                    {coupon.discountType === "Percentage" && "%"} OFF
                  </Typography>
                </div>

                {appliedCouponId === id ? (
                  <Button
                    key={id}
                    className="mt-4 hover:text-yellow border border-yellow"
                    // color="secondary"
                    onClick={removeCoupon}
                    // variant="contained"
                  >
                    <span>Remove</span>
                  </Button>
                ) : (
                  <Button
                    sx={{
                      backgroundColor: palette.primary.main,
                      color: palette.primary.dark,
                      "&:hover": { color: palette.primary.main },
                    }}
                    color="primary"
                    onClick={() => {
                      applyCoupon(coupon, id);
                    }}
                    variant="contained"
                  >
                    <span>Apply</span>
                  </Button>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "center" }}
            >
              No Coupons Available
            </Typography>
          </>
        )}
      </WidgetWrapper>
      <WidgetWrapper mb="1rem">
        <Typography variant="h6" color="primary">
          Order Summary
        </Typography>

        {/* Price */}
        <FlexBetween
          justifyContent="space-between"
          alignItems="center"
          my=".5rem"
        >
          <Typography variant="body1">Price({getTotalTickets()})</Typography>
          <Typography variant="body1">Rs. {basePrice}</Typography>
        </FlexBetween>

        {/* Fees */}
        <FlexBetween
          justifyContent="space-between"
          alignItems="center"
          my=".5rem"
        >
          <Typography variant="body1">Platform Fees</Typography>
          <Typography variant="body1">
            <s>Rs. {fees}</s>
          </Typography>
        </FlexBetween>
        {/* Coupon Discount */}
        {couponCode && (
          <FlexBetween
            justifyContent="space-between"
            alignItems="center"
            my=".5rem"
          >
            <Typography variant="body1">Coupon - {couponCode}</Typography>
            <Typography variant="body1">Rs. {couponPrice}</Typography>
          </FlexBetween>
        )}
        {/* Discount */}
        {couponCode && (
          <FlexBetween
            justifyContent="space-between"
            alignItems="center"
            my=".5rem"
          >
            <Typography variant="body1">After Discount</Typography>
            <Typography variant="body1">Rs. {afterDiscountPrice}</Typography>
          </FlexBetween>
        )}
        {/* Taxes */}
        <FlexBetween
          justifyContent="space-between"
          alignItems="center"
          my=".5rem"
        >
          <Typography variant="body1">Taxes(GST 18%)</Typography>
          <Typography variant="body1">Rs. {gstPrice}</Typography>
        </FlexBetween>

        {/* Horizontal Line */}
        <Divider sx={{ my: "1rem" }} />

        {/* Total Price */}
        <FlexBetween
          justifyContent="space-between"
          alignItems="center"
          my=".5rem"
        >
          <Typography variant="body1">Total Price</Typography>
          <Typography variant="body1">Rs. {finalPrice}</Typography>
        </FlexBetween>
      </WidgetWrapper>
      {/* <Button
        variant="contained"
        onClick={() => { initiatePayment() }}
        sx={{
          backgroundColor: palette.primary.main,
          color: primaryDark,
          "&:hover": { color: palette.primary.main },
          p: "0.6rem",
          width: "100%",
        }}
      >
        Confirm
      </Button> */}
      <FlexBetween gap={"1rem"} style={{ justifyContent: "flex-start" }}>
        <Checkbox
          checked={termsAndCondn}
          onChange={handleChangeTC}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography
          textAlign={"center"}
          sx={{
            gridColumn: "span 4",
            color: palette.primary.main,
            "&:hover": {
              cursor: "pointer",
              color: palette.primary.light,
            },
          }}
        >
          Accept{" "}
          <a
            style={{ textDecoration: "underline", color: "white" }}
            target="_blank"
            href="https://vybez.in/terms-and-conditions/"
          >
            Terms and Conditions
          </a>{" "}
          &{" "}
          <a
            target="_blank"
            style={{ textDecoration: "underline", color: "white" }}
            href="https://vybez.in/refund-policy/"
          >
            Refund policy
          </a>
        </Typography>
      </FlexBetween>
      <FlexBetween gap={"1rem"} style={{ justifyContent: "flex-start" }}>
        <Checkbox
          checked={privacyPolicy}
          onChange={handleChangePP}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography
          textAlign={"center"}
          sx={{
            gridColumn: "span 4",
            color: palette.primary.main,
            "&:hover": {
              cursor: "pointer",
              color: palette.primary.light,
            },
          }}
        >
          Accept{" "}
          <a
            style={{ textDecoration: "underline", color: "white" }}
            target="_blank"
            href="https://vybez.in/privacy-policy/"
          >
            Privacy policy
          </a>
        </Typography>
      </FlexBetween>
      {event.eventType == "GUESTLIST" ? (
        <LoadingButton
          data-umami-event-checkoutpage={event.eventName}
          data-umami-event="Guest-list-button"
          disabled={
            (privacyPolicy == false || termsAndCondn == false) == true
              ? true
              : false
          }
          sx={{
            width: "100%",
            backgroundColor: palette.primary.main,
            color: palette.primary.dark,
            "&:hover": { color: palette.primary.main },
          }}
          color="primary"
          onClick={() => {
            initiateRSVP();
          }}
          loading={loading}
          loadingIndicator="Initiating Payment..."
          variant="contained"
        >
          <span>Confirm & Reserve</span>
        </LoadingButton>
      ) : (
        <LoadingButton
          data-umami-event-checkoutpage={event.eventName}
          data-umami-event="Confirm-and-Pay-button"
          disabled={
            (privacyPolicy == false || termsAndCondn == false) == true
              ? true
              : false
          }
          sx={{
            width: "100%",
            backgroundColor: palette.primary.main,
            color: palette.primary.dark,
            "&:hover": { color: palette.primary.main },
          }}
          color="primary"
          onClick={() => {
            initiatePayment();
          }}
          loading={loading}
          loadingIndicator="Initiating Payment..."
          variant="contained"
        >
          <span>Confirm & Pay</span>
        </LoadingButton>
      )}

      {/* Popup for Applying Coupon */}
      <Popover
        open={popupOpen}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        onClose={handlePopupClose}
      >
        <Box p="2rem" maxWidth="600px" style={{ position: "relative" }}>
          {sampleCoupons.length > 0 ? (
            <CouponCardWidget coupons={sampleCoupons} />
          ) : (
            <WidgetWrapper mb="1rem" p="1rem">
              <Typography
                variant="h6"
                color="primary"
                sx={{ display: "flex", alignItems: "center" }}
              >
                <DiscountIcon sx={{ fontSize: 16, marginRight: "4px" }} /> No
                Coupons Available
              </Typography>
            </WidgetWrapper>
          )}

          <Button
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "1rem",
            }}
            color="primary"
            onClick={handlePopupClose}
          >
            Close
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default CheckoutWidget;
