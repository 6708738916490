import { PersonAddOutlined, PersonRemoveOutlined } from "@mui/icons-material";
import { Box, IconButton, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setFriends } from "state";
import { apiUrl } from "./../config";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import ReactGA from 'react-ga4';
import UserImage from "./UserImage";

const Friend = ({ friendId, name, subtitle, userPicturePath, postType, postId, loggedInUserId, eventDate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const friends = useSelector((state) => state.user.friends);
  const [eventExpired, setEventExpired] = useState(false);

  const isFriend = friends.find((friend) => friend._id === friendId);

  const patchFriend = async () => {
    const response = await fetch(
      `${apiUrl}/users/${_id}/${friendId}`,
      {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };

  const isEventExpired = async () => {
    let eventDay = dayjs(eventDate);
    let currentDay = dayjs();
    let expiry = eventDay.isBefore(currentDay);
    setEventExpired(expiry);
  };

  useEffect(() => {
    isEventExpired();
  }, [eventDate]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-2">
        <UserImage image={userPicturePath} size="55px" />
        <Box
          data-umami-event-friend={friendId}
          data-umami-event="Profile-view-from-post-header"
          onClick={() => {
            navigate(`/profile/${friendId}`);
            navigate(0);
            ReactGA.send({
              hitType: "Profile-view-from-post-header",
              page: '/',
              title: `/profile/${friendId}`,
            });
          }}
          className="cursor-pointer hover:text-white"
        >
          <Typography className="text-white text-lg font-medium capitalize">{name}</Typography>
          <Typography className="text-white text-xs">{subtitle}</Typography>
        </Box>
      </div>
      <div className="flex gap-4">
        {loggedInUserId !== friendId && (
          <Button
            onClick={() => patchFriend()}
            className="text-white bg-[#494949] rounded-lg p-2 text-sm"
          >
            {isFriend ? (
              <Typography variant="caption">Unfollow</Typography>
            ) : (
              <Typography variant="caption">Follow</Typography>
            )}
          </Button>
        )}
      </div>
    </div>
  );
};

export default Friend;
