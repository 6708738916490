import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import { apiUrl } from "./../../config";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";

const ExplorePostWidget = ({ event }) => {
  const [isComments, setIsComments] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const isLiked = Boolean(event.likes[loggedInUserId]);
  const likeCount = Object.keys(event.likes).length;
  const navigate = useNavigate();
  const { palette } = useTheme();
  const mainColor = palette.neutral.main;
  const primaryColor = palette.primary.main;

  const patchLike = async () => {
    const response = await fetch(`${apiUrl}/posts/${event._id}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
  };

  return (
    <div
      className="cursor-pointer bg-[#3A3939] rounded-lg p-4 m-2 shadow-lg transition-transform transform hover:scale-105"
      onClick={() => navigate(`/event/${event._id}`)}
      data-umami-event-checkoutpage={event.eventName}
      data-umami-event="Explore-Event-Event-Click"
    >
      <h3
        className="text-xl font-semibold mb-1"
        style={{ color: primaryColor }}
      >
        {event.eventName}
      </h3>
      <div className="flex items-center text-sm mb-1" style={{ color: mainColor }}>
        <CalendarTodayIcon className="w-4 h-4 mr-2" />
        {event.eventDate ? dayjs(event.eventDate).format("MMMM, DD MMM YYYY") : ""}
      </div>
      <div className="flex items-center text-sm mb-1" style={{ color: mainColor }}>
        <AccessTimeIcon className="w-4 h-4 mr-2" />
        {event.eventStartTime
          ? dayjs(event.eventStartTime).format("hh:mm A")
          : ""}{" "}
        to{" "}
        {event.eventEndTime ? dayjs(event.eventEndTime).format("hh:mm A") : ""}
      </div>
      <div
        className="text-sm flex items-center mb-2"
        style={{ color: mainColor }}
      >
        <LocationOnIcon className="w-4 h-4 mr-2" />
        {event.location}
      </div>
      {event.picturePath && (
        <img
          className="w-full h-[30vh] object-cover rounded-lg"
          alt="event"
          src={`${event.picturePath}`}
        />
      )}
    </div>
  );
};

export default ExplorePostWidget;
