import { Box, useMediaQuery, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import UserWidget from "scenes/widgets/UserWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import ViewWidget from "scenes/widgets/ViewWidget";
import AppBar from "scenes/widgets/AppBar";
import FlexBetween from "components/FlexBetween";
import { seo } from "utilites/utlis";
import VenueListWidget from "scenes/widgets/VenuesListWidget";
import ArtistListWidget from "scenes/widgets/ArtistListWidget";
import UserDetailModel from "scenes/userDetailModel/userDetail";
import { useEffect, useState } from "react";
import { apiUrl } from "config";
import { toast } from "react-toastify";
import UserDetailsCompletionCard from "scenes/userDetailModel/userDetailsCompletionCard";
import { setLogin } from "state";

const HomePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { _id, picturePath, userType, userVerified } = useSelector((state) => state.user);
  const state = useSelector((state) => state);
  console.log(state)
  seo({
    title: "Timeline | Vybez - Urban Frolic",
    metaDescription: "Timeline | Vybez - Urban Frolic",
  });
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${apiUrl}/users/${user?._id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user");
        }
        const result = await response.json();
        dispatch(setLogin({user: result,token:token}));
      
        setUserDetails(result);
        if(result?.isFirstLogin){
          setOpen(result?.isFirstLogin);
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch user");
      }
    };

    if (user?._id && token) {
      fetchUser();
    }
  }, [user._id, reload]);

 

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box>
        <Navbar />
        <Box
          backgroundColor="#1A1A1A"
          width="100%"
          padding={isNonMobileScreens ? "2rem 5rem" : "0.5rem 2rem"}
          display={isNonMobileScreens ? "flex" : "block"}
          gap="0.5rem"
          justifyContent="space-between"
        >
          {/* {isNonMobileScreens && (
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
            <UserWidget userId={user._id} picturePath={picturePath} />
          </Box>
        )} */}

          <Box
            flexBasis={isNonMobileScreens ? "70%" : undefined}
            // mt={isNonMobileScreens ? undefined : "1rem"}
            // ml={isNonMobileScreens ? undefined : "2rem"}
            paddingBottom="4rem"
          >
            {/* {((userType === 'EVENT_ORGANISER' || userType === 'INFULENCER') && userVerified === true) ?
            <MyPostWidget userType={userType} verifiedUser={userVerified} picturePath={picturePath} />
            : ''} */}
            <Box m={isNonMobileScreens ? undefined : "1rem"}>
              <FlexBetween>
                <Typography
                  variant="h6"
                  fontSize={"28px"}
                  textAlign={"left"}
                  letterSpacing={"0.1rem"}
                >
                  Feeds
                </Typography>
                <Typography
                  variant="caption"
                  fontSize={"14px"}
                  textAlign={"right"}
                  letterSpacing={"0.1rem"}
                  color={"primary"}
                >
                  {" "}
                  Recents
                </Typography>
              </FlexBetween>
            </Box>

            <PostsWidget userId={user._id} />
          </Box>
          {isNonMobileScreens && (
            <Box flexBasis="28%">
              {userDetails?.isFirstLogin === false &&
                userDetails?.steps <
                  (userDetails?.userType === "VYBER" ? 8 : 3) && (
                  <>
                    <UserDetailsCompletionCard
                      userDetails={userDetails}
                      setOpen={setOpen}
                    />
                    <Box m="1rem 0" />
                  </>
                )}
              <AdvertWidget />
              <Box m="1rem 0" />
              <VenueListWidget />
              <Box m="1rem 0" />
              <ArtistListWidget />
              <Box m="1rem 0" />
              <FriendListWidget userId={user._id} />
            </Box>
          )}
        </Box>
      </Box>
      <UserDetailModel
        open={open}
        handleClose={handleClose}
        setReload={setReload}
        reload={reload}
      />
    </>
  );
};

export default HomePage;
