import React, { useState } from "react";
import Photo from "../../assests/images/artist.jpg";

const ArtistDisplay = (artistData) => {
  const [imgErr, setImgErr] = useState(false);
  return (
    <div
      key={artistData.artist.id}
      className="flex-shrink-0 w-[130px] h-[130px] sm:w-[160px] sm:h-[160px] md:w-[180px] md:h-[180px] relative"
    >
      <img
        src={imgErr ? Photo : artistData?.artist?.picturePath}
        alt={artistData?.artist?.fullName}
        onError={() => setImgErr(true)}
        className="w-full h-full object-cover rounded-lg"
      />
      <div className="absolute inset-0 rounded bg-gradient-to-b from-transparent via-black/30 to-black/90"></div>
      <div className="absolute bottom-2 left-2 text-white !font-bold text-sm rounded px-1">
        {artistData.artist.nickName != undefined &&
        artistData.artist.nickName != null &&
        artistData.artist.nickName != ""
          ? artistData.artist.nickName
          : `${artistData?.artist?.fullName}`}
      </div>
    </div>
  );
};

export default ArtistDisplay;
