import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import PostWidget from "./PostWidget";
import { apiUrl } from "./../../config";
import { Button, CircularProgress } from "@mui/material";

const PostsWidget = ({ userId, isProfile = false }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const token = useSelector((state) => state.token);

  const [currentPage, setCurrentPage] = useState(1); 
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false); 

  console.log(posts)

  const fetchPosts = async (page) => {
    setLoading(true);
    try {
      const endpoint = isProfile
        ? `${apiUrl}/posts/${userId}/posts?page=${page}&limit=10`
        : `${apiUrl}/posts?page=${page}&limit=10`;

      const response = await fetch(endpoint, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      const data = await response.json();

      if (data.posts?.length > 0) {
        if (page === 1) {
          // Initial fetch, replace posts
          dispatch(setPosts({ posts: data.posts }));
        } else {
          // Append new posts to existing posts
          dispatch(setPosts({ posts: [...posts, ...data.posts] }));
        }
        // Update hasMore based on API response
        setHasMore(data.hasMore);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch initial posts on component mount
    fetchPosts(1);
  }, [isProfile, userId]);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchPosts(nextPage);
  };

  return (
    <>
      {posts?.map((post) => (
        <PostWidget
          key={post._id}
          postId={post._id}
          postUserId={post.userId}
          name={`${post?.fullName}`}
          description={post.description}
          location={post.location}
          picturePath={post.picturePath}
          userPicturePath={post.userPicturePath}
          likes={post.likes}
          commentsCount={post.commentCount}
          postType={post.postType}
          eventDate={post.eventDate}
          eventType={post.eventType}
          bannerPath={post.bannerPath}
        />
      ))}

      {/* Load More Button */}
      {hasMore && !loading && (
        <div className="text-center">
          <Button
            onClick={handleLoadMore}
            style={{ marginTop: "20px" }}
            variant="contained"
            className="mt-4 hover:text-yellow border border-yellow"
          >
            Load More
          </Button>
        </div>
      )}

      {/* Loading Indicator */}
      {loading && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CircularProgress />
        </div>
      )}
    </>
  );
};

export default PostsWidget;
