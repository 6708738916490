import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "./../../config";
import ExplorePostWidget from "./ExplorePostWidget";
import { IconButton, InputBase } from "@mui/material";
import { Search } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const ExplorePostsWidget = ({ userId, isProfile = false }) => {
  const dispatch = useDispatch();
  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagTypes, setTagTypes] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [weekTags] = useState([
    { tagName: "This Week", tagType: "DATEFILTER" },
    { tagName: "Today", tagType: "DATEFILTER" },
    { tagName: "Tomorrow", tagType: "DATEFILTER" },
  ]);
  const [searchText, setSearchText] = useState("");
  const token = useSelector((state) => state.token);
  const [dateSearchType, setSearchType] = useState();
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const getPosts = async () => {
    try {
      const body = {
        ...(tagTypes.length > 0 && { postTags: tagTypes }),
        ...(dateSearchType && { dateSearchType }),
        ...(searchText && { searchText }),
      };

      const response = await fetch(`${apiUrl}/posts/event-posts`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      setPosts(data);
    } catch (e) {
      setPosts([]);
    }
  };

  const getExploreTags = async () => {
    const response = await fetch(`${apiUrl}/tags`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tagType: "explore" }),
    });
    const data = await response.json();
    setTags(data);
  };

  const addTagForSearch = (tag) => {
    setTagTypes((prevSelected) =>
      prevSelected.includes(tag) ? prevSelected : [...prevSelected, tag]
    );
  };

  const removeTagForSearch = (tag) => {
    setTagTypes((prevSelected) => prevSelected.filter((t) => t !== tag));
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    setSelectedDate(value);
    setSearchType(value);
    setIsFilterOpen(false);
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    addTagForSearch(value);
    setTags((prevTags) => prevTags.filter((tag) => tag !== value));
    setIsFilterOpen(false);
  };

  const handleRemoveDate = () => {
    setSelectedDate(null);
    setSearchType(null);
  };

  const handleRemoveCategory = (category) => {
    removeTagForSearch(category);
    setTags((prevTags) => [...prevTags, category]);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const toggleFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  useEffect(() => {
    getPosts();
    getExploreTags();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getPosts();
  }, [tagTypes, dateSearchType, searchText]);

  const FilterSection = ({ isMobile }) => (
    <div
      className={`${
        isMobile
          ? "block md:hidden"
          : "w-full md:w-1/4 bg-[#3A3939] rounded-xl p-4 h-min sm:block hidden"
      }`}
    >
      <h2 className="text-lg font-semibold text-gray-100 mb-4">Filter</h2>
      {/* Date Filter */}
      <div className="mb-6">
        <label
          htmlFor={`dateFilter-${isMobile ? "mobile" : "desktop"}`}
          className="text-gray-200 block mb-2"
        >
          Date
        </label>
        <select
          id={`dateFilter-${isMobile ? "mobile" : "desktop"}`}
          value={selectedDate || ""}
          onChange={handleDateChange}
          className="w-full bg-[#4f4f4e] text-gray-200 border border-gray-600 rounded-lg p-2 focus:ring-2 focus:ring-primary focus:outline-none"
        >
          <option value="" className="text-gray-400">
            Select a Date
          </option>
          {weekTags.map((tag) => (
            <option key={tag.tagName} value={tag.tagName}>
              {tag.tagName}
            </option>
          ))}
        </select>
      </div>
      {/* Categories Filter */}
      <div>
        <label
          htmlFor={`categoriesFilter-${isMobile ? "mobile" : "desktop"}`}
          className="text-gray-200 block mb-2"
        >
          Categories
        </label>
        <select
          id={`categoriesFilter-${isMobile ? "mobile" : "desktop"}`}
          value={tagTypes[0] || ""}
          onChange={handleCategoryChange}
          className="w-full bg-[#4f4f4e] text-gray-200 border border-gray-600 rounded-lg p-2 focus:ring-2 focus:ring-primary focus:outline-none"
        >
          <option value="" className="text-gray-400">
            Select a Category
          </option>
          {tags.map((tag) => (
            <option key={tag} value={tag}>
              {tag}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  return (
    <div className="w-full px-6 py-8 flex flex-col md:flex-row gap-6">
      {/* Desktop Filter Section */}
      <FilterSection isMobile={false} />

      {/* Mobile Filter Popup */}
      <div
        className={`${
          isFilterOpen
            ? "absolute top-0 left-0 w-full h-screen bg-[#00000080] flex items-center justify-center z-50"
            : "hidden"
        } md:hidden`}
        onClick={() => setIsFilterOpen(false)}
      >
        <div
          className="bg-[#3A3939] w-4/5 md:w-1/4 rounded-xl p-6"
          onClick={(e) => e.stopPropagation()}
        >
          <FilterSection isMobile={true} />
        </div>
      </div>

      {/* Posts Section */}
      <div className="w-full md:w-3/4">
        <div className="flex flex-col sm:flex-row justify-between items-center">
          <h2 className="text-xl font-semibold text-gray-100 mb-6">
            Discover Amazing Events Near You
          </h2>
          <div className="flex justify-between items-start gap-2">
            <div className="flex bg-[#3A3939] rounded-lg gap-4 py-1 px-6 mb-4">
              <InputBase
                placeholder="Search..."
                className="w-full text-white"
                value={searchText}
                onChange={handleSearchChange}
              />
              <IconButton>
                <Search />
              </IconButton>
            </div>
            <div className="md:hidden flex justify-end bg-[#3A3939] p-1 rounded-lg">
              <IconButton onClick={toggleFilter}>
                <FilterAltIcon className="text-white" />
              </IconButton>
            </div>
          </div>
        </div>

        {tagTypes.length > 0 || selectedDate ? (
          <div className="w-full mb-6">
            <div className="flex flex-wrap gap-4">
              {selectedDate && (
                <div className="flex items-center bg-[#3A3939] text-gray-200 rounded-lg px-3 py-2 space-x-2">
                  <span>{selectedDate}</span>
                  <button
                    onClick={handleRemoveDate}
                    className="bg-[#4f4f4e] text-white rounded-full px-1 hover:bg-gray-500"
                  >
                    <CloseIcon style={{ fontSize: "0.8rem" }} />
                  </button>
                </div>
              )}

              {tagTypes.map((category) => (
                <div
                  key={category}
                  className="flex items-center bg-[#3A3939] text-gray-200 rounded-lg px-3 py-2 space-x-2"
                >
                  <span>{category}</span>
                  <button
                    onClick={() => handleRemoveCategory(category)}
                    className="bg-[#4f4f4e] text-white rounded-full px-1 hover:bg-gray-500"
                  >
                    <CloseIcon style={{ fontSize: "0.8rem" }} />
                  </button>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full">
          {posts.map((event) => (
            <ExplorePostWidget key={event.id} event={event} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExplorePostsWidget;
