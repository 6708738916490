import {
  Typography,
  useTheme,
  LinearProgress,
  Box,
  Button,
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";

const ProfileCompletionCard = ({ userDetails, setOpen }) => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.primary.main;
  const medium = palette.neutral.medium;

  const steps = userDetails?.steps;
  const userType = userDetails?.userType;
  const totalSteps = userType === "VYBER" ? 8 : 3;
  const progress = Math.min((steps / totalSteps) * 100, 100);

  return (
    <WidgetWrapper sx={{ backgroundColor: "#3A3939", padding: "16px" }}>
      <Typography color={dark} variant="h5" fontWeight="500" gutterBottom>
        Profile Completion
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        mt={2}
      >
        <Box width="100%">
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{
              height: "10px",
              borderRadius: "5px",
              backgroundColor: medium,
              "& .MuiLinearProgress-bar": { backgroundColor: main },
            }}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
          >
            <Typography color={medium} variant="body2">
              {`Steps Completed: ${steps} / ${totalSteps}`}
            </Typography>
            <Typography color={dark} variant="body2" fontWeight="bold">
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Box>
        {steps < totalSteps && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            className="mt-4 hover:text-yellow border border-yellow"
            sx={{ marginTop: "16px" }}
          >
            Complete Now
          </Button>
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default ProfileCompletionCard;
