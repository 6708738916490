import { useState } from "react";
import { Box, IconButton } from "@mui/material";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";

const UserImage = ({ image, size = "60px" }) => {
  const [imgErr, setImgErr] = useState(false);

  return (
    <Box className={`w-[${size}] h-[${size}]`}>
      {imgErr === false ? (
        <img
          className="object-cover rounded-full"
          width={size}
          height={size}
          alt="user"
          src={`${image}`}
          onError={() => {
            setImgErr(true);
          }}
        />
      ) : (
        <IconButton size="large">
          <AccountCircleOutlined style={{ fontSize: "130%" }} />
        </IconButton>
      )}
    </Box>
  );
};

export default UserImage;
