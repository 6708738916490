import { Box, Typography } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { apiUrl } from "config";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Photo from "../../assests/images/artist.jpg";

const ArtistListWidget = () => {
  const [artists, setArtists] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchArtists();
  }, []);

  const fetchArtists = async () => {
    try {
      const response = await fetch(`${apiUrl}/users/artists/1/6`);
      const data = await response.json();
      setArtists(data.artists);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const navigateToArtists = () => {
    navigate(`/artists`);
  };

  const navigateToArtistsDetail = (artist) => {
    navigate(`/artist-detail/${artist.profileSlug}`);
  };

  return (
    <WidgetWrapper style={{ backgroundColor: "#3A3939" }}>
      <Typography
        color="#FFFFFF"
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Artists
      </Typography>
      <Box display="flex" position="relative" height="80px">
        {artists.slice(0, 5).map((artist, index) => (
          <Box
            key={artist.id}
            sx={{
              width: 80,
              height: 80,
              borderRadius: "50%",
              position: "absolute",
              left: `${index * 35}px`,
              top: 0,
              zIndex: artists.length + index,
              overflow: "visible",
              cursor: "pointer",
              transition:
                "transform 0.2s ease, box-shadow 0.2s ease, z-index 0.2s ease",
              "&:hover": {
                transform: "translateY(-10px)",
                boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
                zIndex: 1000,
              },
              "&:hover .tooltip": {
                visibility: "visible",
                opacity: 1,
                transform: "translateY(0)",
              },
            }}
            onClick={() => navigateToArtistsDetail(artist)}
          >
            <Box
              component="img"
              src={artist.picturePath}
              alt={artist.venuName}
              onError={(e) => e.target.src = Photo}
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            />
            <Box
              className="tooltip"
              sx={{
                position: "absolute",
                top: "100%",
                left: "50%",
                transform: "translateX(-50%) translateY(-10px)",
                mt: 1,
                px: 1,
                py: 0.5,
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                color: "#FFF",
                borderRadius: "4px",
                fontSize: "12px",
                whiteSpace: "nowrap",
                visibility: "hidden",
                opacity: 0,
                transition: "opacity 0.2s ease, transform 0.2s ease",
                zIndex: 100,
              }}
            >
              {artist.nickName && artist.nickName.trim()
                ? artist.nickName
                : `${artist?.fullName}`}
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            width: 80,
            height: 80,
            borderRadius: "50%",
            backgroundColor: "rgba(106, 106, 106, 0.8)",
            position: "absolute",
            left: `${Math.min(artists.length, 5) * 35}px`,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#FFF",
            fontSize: "12px",
            fontWeight: "400",
            zIndex: 50,
            cursor: "pointer",
            transition:
              "transform 0.2s ease, box-shadow 0.2s ease, z-index 0.2s ease",
            "&:hover": {
              transform: "translateY(-10px)",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.25)",
              zIndex: 1000,
            },
          }}
          onClick={navigateToArtists}
        >
          View All
        </Box>
      </Box>
    </WidgetWrapper>
  );
};

export default ArtistListWidget;
