import React, { useState } from "react";
import Photo from "../../assests/images/artist.jpg";

const ArtistCard = ({ artist }) => {
  const [imgErr, setImgErr] = useState(false);

  return (
    <div className="bg-[#2A2A2A] rounded-[20px] shadow-md overflow-hidden w-full transition-transform transform hover:scale-105">
      {/* Image Section */}
      <div className="relative">
        <img
          src={imgErr ? Photo : artist?.picturePath}
          alt={artist?.fullName || "Artist Image"}
          onError={() => setImgErr(true)}
          className="w-full h-48 object-cover bg-[#000000]"
        />
      </div>

      {/* Content Section */}
      <div className="p-4">
        {/* Subtext */}
        <div className="text-yellow text-xs">
          {artist?.nickName ? artist.fullName : ""}
        </div>

        {/* Main Title */}
        <h3 className="font-semibold text-lg">
          {artist?.nickName || artist?.fullName || ""}
        </h3>
      </div>
    </div>
  );
};

export default ArtistCard;
