import React, { useEffect, useState } from "react";
import { Button, Dialog, Typography, Box, useTheme } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PiGuitar } from "react-icons/pi";
import { IoTicketOutline } from "react-icons/io5";
import { GiBarracksTent } from "react-icons/gi";
import { FaRegUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AgeSelection,
  AtmosphereSelection,
  EventVisitsSelection,
  GenresSelection,
  LocationSelection,
  ProfileUpload,
  UserTypeSelection,
  VenueDetails,
} from "./userDetailsComponent";
import { apiUrl } from "config";

const UserDetailModel = ({ handleClose, open, setReload, reload }) => {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);
  const { palette } = useTheme();
  const [steps, setSteps] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState();
  const [skipLoading, setSkipLoading] = useState();
  const [loadingModalData, setLoadingModalData] = useState();



  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoadingModalData(true)
        const response = await fetch(`${apiUrl}/users/${user?._id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user");
        }

        const result = await response.json();
        setUserData(result);
        setLoadingModalData(false)
        setSteps(result?.steps);
      } catch (error) {
        setLoadingModalData(false)
        toast.error("Failed to fetch user");
      }
    };
    if (user?._id) {
      fetchUser();
    }
  }, [user?._id]);

  const cards = [
    {
      title: "Discover Events Around Me",
      icon: <FaRegUser className="text-2xl" />,
      value: "VYBER",
    },
    {
      title: "Artist",
      icon: <PiGuitar className="text-2xl" />,
      value: "ARTIST",
    },
    {
      title: "Event Organizer",
      icon: <IoTicketOutline className="text-2xl" />,
      value: "EVENT_ORGANISER",
    },
    {
      title: "Venue Owner",
      icon: <GiBarracksTent className="text-2xl" />,
      value: "VENU",
    },
  ];

  const locationOptions = [{ label: "Bengaluru", value: "Bengaluru" }];
  const genresOptions = [
    "EDM",
    "Hip-Hop",
    "Dance",
    "Techno",
    "Bollywood",
    "Karaoke",
    "New-age",
    "Reggae",
    "Pop",
    "Rap",
    "R&B",
    "Rock",
    "Salsa",
    "Latin",
    "Country",
    "Indie",
    "Disco",
    "Funk",
    "Soul",
    "Jazz",
    "Classical",
    "Electro",
    "Trap",
    "K-Pop",
    "Punk",
    "Trance",
  ];
  const eventVisits = [
    "Weekly",
    "Monthly",
    "Occasionally",
    "Rarely",
    "First-time",
  ];
  const atmosphereOptions = [
    "Lively",
    "Energetic",
    "Chill",
    "Relaxed",
    "Intimate",
    "Cozy",
    "Trendy",
    "Diverse",
    "Inclusive",
    "Peace",
    "Silent",
  ];
  const age = ["Under 18", "18-24", "25-34", "35-44", "55 and over"];

  const handleBack = async () => {
    const backStep = steps - 1;
    setSteps(backStep);
  };

  const handleSkip = async () => {
    setSkipLoading(true);
    try {
      const response = await fetch(`${apiUrl}/users/update-user-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: userData._id, isFirstLogin: false }),
      });

      if (!response.ok) {
        throw new Error("Failed to update details");
      }

      const result = await response.json();
      toast.success("Details updated successfully");
      setReload(!reload);
      formik.resetForm();
      handleClose();
    } catch (error) {
      toast.error(error.message || "Failed to update details");
      setSkipLoading(false);
    }
    setSkipLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      userType: userData?.userType || "",
      location: userData?.location || "",
      genreType: userData?.genreType || [],
      atmosphere: userData?.atmosphere || [],
      eventVisits: userData?.eventVisits || "",
      ageRange: userData?.ageRange || "",
      venueName: userData?.venueName || "",
      profilePicture: userData?.profilePicture,
    },
    enableReinitialize: true,
    validationSchema: Yup.lazy((values) => {
      const userType = values.userType;

      const commonSchemas = {
        profilePicture: Yup.mixed()
          .test("required", "Profile picture is required", function (value) {
            if (["ARTIST", "EVENT_ORGANISER", "VENU"].includes(userType)) {
              return !!value;
            }
            return true;
          })
          .test(
            "fileSize",
            "File size must be less than 2MB",
            function (value) {
              if (value) {
                return value.size <= 2 * 1024 * 1024;
              }
              return true;
            }
          )
          .test("fileType", "Unsupported file type", function (value) {
            if (value) {
              return ["image/jpeg", "image/png"].includes(value.type);
            }
            return true;
          }),

        venueName: Yup.string().when("userType", {
          is: "VENU",
          then: Yup.string().required("Venue name is required"),
        }),

        location: Yup.string().when("userType", {
          is: (userType) => userType === "VYBER" || userType === "VENU",
          then: Yup.string().required("Location is required"),
        }),

        genreType: Yup.array()
          .of(Yup.string())
          .min(1, "Select at least one genre")
          .when("userType", {
            is: "VYBER",
            then: Yup.array().required("Genres are required"),
          }),

        atmosphere: Yup.array()
          .of(Yup.string())
          .min(1, "Select at least one atmosphere")
          .when("userType", {
            is: "VYBER",
            then: Yup.array().required("Atmosphere are required"),
          }),

        eventVisits: Yup.string().when("userType", {
          is: "VYBER",
          then: Yup.string().required("Please select an one Event Visits"),
        }),

        ageRange: Yup.string().when("userType", {
          is: "VYBER",
          then: Yup.string().required("Please select an age range"),
        }),
      };

      // Validate based on step
      switch (steps) {
        case 1:
          return Yup.object({
            // userType: Yup.string().required("Please select a user type"),
          });

        case 2:
          if (["ARTIST", "EVENT_ORGANISER"].includes(userType)) {
            return Yup.object({
              profilePicture: commonSchemas.profilePicture,
            });
          } else if (userType === "VENU") {
            return Yup.object({
              profilePicture: commonSchemas.profilePicture,
              venueName: commonSchemas.venueName,
              location: commonSchemas.location,
            });
          } else if (userType === "VYBER") {
            return Yup.object({
              location: commonSchemas.location,
            });
          }
          break;

        case 3:
          if (userType === "VYBER") {
            return Yup.object({
              genreType: commonSchemas.genreType,
            });
          }
          break;

        case 4:
          if (userType === "VYBER") {
            return Yup.object({
              atmosphere: commonSchemas.atmosphere,
            });
          }
          break;

        case 5:
          if (userType === "VYBER") {
            return Yup.object({
              eventVisits: commonSchemas.eventVisits,
            });
          }
          break;

        case 6:
          if (userType === "VYBER") {
            return Yup.object({
              ageRange: commonSchemas.ageRange,
            });
          }
          break;

        case 7:
          if (userType === "VYBER") {
            return Yup.object({
              profilePicture: commonSchemas.profilePicture,
            });
          }
          break;

        default:
          return Yup.object();
      }
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const formData = new FormData();
      const newStep = steps + 1;
      if (newStep <= totalSteps) {
        setSteps(newStep);
      } else {
        formData.set("isFirstLogin", false);
      }
      formData.append("userId", user?._id);
      formData.append("steps", newStep);
      Object.entries(values).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((v) => formData.append(key, v));
        } else {
          formData.append(key, value);
        }
      });

      try {
        const response = await fetch(`${apiUrl}/users/update-user-details`, {
          method: "POST",
          body: formData,
        });
        const result = await response.json();
        if (steps === totalSteps) {
          if (!result.error) {
            toast.success("Details updated successfully");
            setReload(!reload);
            formik.resetForm();
            handleClose();
          } else {
            toast.error(result.message);
          }
        }
      } catch {
        setLoading(false);
        toast.error("Failed to update details");
      }
      setLoading(false);
    },
  });
  const totalSteps = formik.values.userType === "VYBER" ? 7 : 2;

  const handleUpdateUserType = async () => {
    await formik.submitForm();
  };

  const renderStepContent = () => {
    const { values, setFieldValue, errors, touched } = formik;

    switch (steps) {
      case 1:
        return (
          <UserTypeSelection
            cards={cards}
            setFieldValue={setFieldValue}
            handleUpdateUserType={handleUpdateUserType}
            errors={errors}
            touched={touched}
          />
        );
      case 2:
        if (
          values.userType === "ARTIST" ||
          values.userType === "EVENT_ORGANISER"
        ) {
          return (
            <ProfileUpload
              picture={values.profilePicture}
              onUpload={(file) => setFieldValue("profilePicture", file)}
              error={errors.profilePicture}
              touched={touched.profilePicture}
              setFieldValue={setFieldValue}
            />
          );
        } else if (values.userType === "VENU") {
          return (
            <VenueDetails
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
          );
        } else if (values.userType === "VYBER") {
          return (
            <LocationSelection
              locationOptions={locationOptions}
              setFieldValue={setFieldValue}
              value={values.location}
              error={errors.location}
              touched={touched.location}
            />
          );
        }
        break;
      case 3:
        if (values.userType === "VYBER") {
          return (
            <GenresSelection
              name="genreType"
              genres={genresOptions}
              setFieldValue={setFieldValue}
              touched={touched.genreType}
              error={errors.genreType}
              value={values.genreType}
              palette={palette}
            />
          );
        }
        break;
      case 4:
        if (values.userType === "VYBER") {
          return (
            <AtmosphereSelection
              name="atmosphere"
              atmosphereOptions={atmosphereOptions}
              value={values.atmosphere}
              setFieldValue={setFieldValue}
              touched={touched.atmosphere}
              error={errors.atmosphere}
              palette={palette}
            />
          );
        }
        break;

      case 5:
        if (values.userType === "VYBER") {
          return (
            <EventVisitsSelection
              name="eventVisits"
              eventVisits={eventVisits}
              value={formik.values.eventVisits}
              setFieldValue={formik.setFieldValue}
              touched={formik.touched.eventVisits}
              error={formik.errors.eventVisits}
              palette={palette}
            />
          );
        }
        break;
      case 6:
        if (values.userType === "VYBER") {
          return (
            <AgeSelection
              name="ageRange"
              ageOptions={age}
              value={values.ageRange}
              setFieldValue={setFieldValue}
              touched={touched.ageRange}
              error={errors.ageRange}
              palette={palette}
            />
          );
        }
        break;
      case 7:
        if (values.userType === "VYBER") {
          return (
            <ProfileUpload
              picture={values.profilePicture}
              onUpload={(file) => setFieldValue("profilePicture", file)}
              error={errors.profilePicture}
              touched={touched.profilePicture}
              setFieldValue={setFieldValue}
            />
          );
        }
      default:
        return <Typography>Step not found</Typography>;
    }
  };

  const renderNavigationButtons = () => {
    return (
      <Box
        display="flex"
        flexDirection="row-reverse"
        justifyContent="space-between"
        mt={3}
      >
        <Box
          display="flex"
          gap={3}
          // flexDirection="row-reverse"
          justifyContent="space-between"
        >
          <Button
            onClick={handleSkip}
            disabled={!formik.isValid & skipLoading}
            className="mt-4 hover:text-yellow border border-yellow"
          >
            {skipLoading ? "closing..." : "skip & close"}
          </Button>
          {steps !== 1 &&
            (steps === totalSteps ? (
              <Button
                type="submit"
                variant="contained"
                disabled={!formik.isValid && loading}
                className="mt-4 hover:text-yellow border border-yellow"
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disabled={!formik.isValid && loading}
                className="mt-4 hover:text-yellow border border-yellow"
              >
                {loading ? "..." : "Next"}
              </Button>
            ))}
        </Box>
        {formik.values.userType === "VYBER" && steps > 2 && (
          <Button disabled={!formik.isValid} onClick={handleBack}>
            Back
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Dialog open={open} fullWidth>
      <form onSubmit={formik.handleSubmit} className="p-6">
        {/* {renderStepContent() } */}
        {console.log(loadingModalData)}
        {loadingModalData ? "Loading ..." : renderStepContent()}
        {renderNavigationButtons()}
      </form>
    </Dialog>
  );
};

export default UserDetailModel;
