import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ErrorMessage, Field, useField } from "formik";
import Dropzone, { useDropzone } from "react-dropzone";

const AgeSelection = ({ ageOptions, value, name, setFieldValue, palette }) => {
  const handleAgeClick = (age) => {
    setFieldValue(name, age);
  };

  return (
    <div className="flex-col justify-between p-2">
      <div className="text-center">
        <Typography variant="h3" className="text-white">
          Help us with your age to tailor your experience
        </Typography>
      </div>

      {/* Age options */}
      <Stack
        style={{ margin: "30px auto" }}
        direction="row"
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent="center"
        gap={"10px"}
      >
        {ageOptions.map((age) => (
          <Chip
            key={age}
            onClick={() => handleAgeClick(age)}
            style={{
              fontSize: "12px",
              padding: "1rem 0.5rem",
              border: `0.5px solid ${palette.primary.main}`,
              borderRadius: "5px",
              backgroundColor:
                value === age ? palette.primary.main : palette.grey[900],
              color: value === age ? palette.grey[900] : "white",
              cursor: "pointer",
            }}
            label={age}
            variant="outlined"
          />
        ))}
      </Stack>
    </div>
  );
};

const AtmosphereSelection = ({
  atmosphereOptions,
  value,
  setFieldValue,
  touched,
  error,
  palette,
}) => {
  const handleAtmosphereClick = (atmosphere) => {
    const newAtmosphere = value.includes(atmosphere)
      ? value.filter((item) => item !== atmosphere)
      : [...value, atmosphere];

    setFieldValue("atmosphere", newAtmosphere);
  };

  return (
    <div className="text-center">
      <Typography variant="h3" className="text-white">
        What kind of atmosphere do you prefer?
      </Typography>

      {/* Atmosphere options */}
      <Stack
        style={{ margin: "30px auto" }}
        direction="row"
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent="center"
        gap={"10px"}
      >
        {atmosphereOptions.map((atmosphere) => (
          <Chip
            key={atmosphere}
            onClick={() => handleAtmosphereClick(atmosphere)}
            style={{
              fontSize: "12px",
              padding: "1rem 0.5rem",
              border: `0.5px solid ${palette.primary.main}`,
              borderRadius: "5px",
              backgroundColor: value.includes(atmosphere)
                ? palette.primary.main
                : palette.grey[900],
              color: value.includes(atmosphere) ? palette.grey[900] : "white",
              cursor: "pointer",
            }}
            label={atmosphere}
            variant="outlined"
          />
        ))}
      </Stack>

      {/* Display validation errors */}
      {touched && error && (
        <Typography variant="body2" color="error" className="text-center" mt={1}>
          {error}
        </Typography>
      )}
    </div>
  );
};

const EventVisitsSelection = ({
  name,
  eventVisits,
  value,
  setFieldValue,
  touched,
  error,
  palette,
}) => {
  const handleEventVisitClick = (visit) => {
    setFieldValue(name, value === visit ? "" : visit);
  };

  return (
    <div>
      {/* Title */}
      <div className="text-center">
        <Typography variant="h3" className="text-white">
          How often do you go out for these events?
        </Typography>
      </div>

      {/* Event Visits Options */}
      <Stack
        style={{ margin: "30px auto" }}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        gap="10px"
      >
        {eventVisits.map((visit) => (
          <Chip
            key={visit}
            onClick={() => handleEventVisitClick(visit)}
            style={{
              fontSize: "12px",
              padding: "1rem 0.5rem",
              border: `0.5px solid ${palette.primary.main}`,
              borderRadius: "5px",
              backgroundColor:
                value === visit ? palette.primary.main : palette.grey[900],
              color: value === visit ? palette.grey[900] : "white",
              cursor: "pointer",
            }}
            label={visit}
            variant="outlined"
          />
        ))}
      </Stack>

      {/* Validation Error Display */}
      {touched && error && (
        <Typography variant="body2" color="error" className="text-center" mt={1}>
          {error}
        </Typography>
      )}
    </div>
  );
};

const GenresSelection = ({
  genres,
  name,
  setFieldValue,
  touched,
  error,
  value,
  palette
}) => {
  const handleGenreClick = (selectedGenre) => {
    const updatedGenres = value?.includes(selectedGenre)
      ? value.filter((genre) => genre !== selectedGenre)
      : [...value, selectedGenre];
    setFieldValue(name, updatedGenres);
  };

  return (
    <div>
      {/* Title */}
      <div className="text-center">
        <Typography variant="h3" className="text-white">
          Which genres do you vibe with?
        </Typography>
      </div>

      {/* Genres options */}
      <Stack
        style={{ margin: "30px auto" }}
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
        gap="10px"
      >
        {genres.map((genre) => (
          <Chip
            key={genre}
            onClick={() => handleGenreClick(genre)}
            style={{
              fontSize: "12px",
              padding: "1rem 0.5rem",
              border: `0.5px solid ${palette.primary.main}`,
              borderRadius: "5px",
              cursor: "pointer",
              backgroundColor:
              value.includes(genre) ? palette.primary.main : palette.grey[900],
              color: value.includes(genre) ? palette.grey[900] : "white",
            }}
            label={genre}
            variant="outlined"
          />
        ))}
      </Stack>

      {/* Display validation errors */}
      {error && (
        <Typography variant="body2" color="error" className="text-center" mt={1}>
          {error}
        </Typography>
      )}
    </div>
  );
};

const LocationSelection = ({
  value,
  onChange,
  onBlur,
  locationOptions,
  setFieldValue,
  touched,
  error,
}) => {
  return (
    <div className="flex-col justify-between">
      {/* Title */}
      <div className="text-center">
        <Typography variant="h3" className="text-white">
          Where would you like to vibe?
        </Typography>
      </div>

      {/* Select Field */}
      <Box
        sx={{
          margin: "30px auto",
          position: "relative",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Select
          native
          name="location"
          value={value || ""}
          onChange={(e) => {
            setFieldValue("location", e.target.value);
          }}
          onBlur={onBlur}
          sx={{
            width: "100%",
            borderRadius: "4px",
          }}
        >
          <option value="" disabled>
            Select a location
          </option>
          {locationOptions?.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </Select>

        {/* Validation Error */}
        {error && (
          <Typography variant="body2" color="error" className="text-center" mt={1}>
            {error}
          </Typography>
        )}
      </Box>
    </div>
  );
};

const VenueDetails = ({ values, errors, touched, setFieldValue }) => {
  const [preview, setPreview] = useState(values?.profilePicture);
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreview(fileUrl);
      setFieldValue("profilePicture", file, false); 
    }
  };
  

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".jpg,.jpeg,.png",
    multiple: false,
  });

  return (
    <div className="flex flex-col justify-between">
      <Typography variant="h4" sx={{ color: "white", mb: 3 }}>
        Venue Details
      </Typography>

      {/* Dropzone for Venue Picture */}
      <Box
        sx={{
          margin: "30px auto",
          position: "relative",
          borderRadius: "50%",
        }}
      >
        <Box
          {...getRootProps()}
          sx={{
            width: "180px",
            height: "180px",
            border: !preview
              ? `2px dashed ${errors.picturePath ? "white" : "#1976d2"}`
              : "none",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#2A2A2A",
            overflow: "hidden",
            position: "relative",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: !preview ? "#2A2A2A" : "transparent",
            },
          }}
        >
          <input {...getInputProps()} />
          {!preview ? (
            <Typography align="center" color="white">
              Drag or click to upload profile picture
            </Typography>
          ) : (
            <img
              src={preview}
              alt="Profile Preview"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          )}
        </Box>
        {errors.profilePicture && (
          <Typography variant="body2" color="error" className="text-center" mt={2}>
            {errors.profilePicture}
          </Typography>
        )}
      </Box>

      {/* Venue Name and Location */}
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Venue Name"
          name="venueName"
          fullWidth
          value={values.venueName || ""}
          onChange={(e) => setFieldValue("venueName", e.target.value)}
          error={touched.venueName && Boolean(errors.venueName)}
          helperText={touched.venueName && errors.venueName}
        />

        <TextField
          label="Location"
          name="location"
          fullWidth
          value={values.location || ""}
          onChange={(e) => setFieldValue("location", e.target.value)}
          error={touched.location && Boolean(errors.location)}
          helperText={touched.location && errors.location}
        />
      </Box>
    </div>
  );
};

const ProfileUpload = ({ picture, onUpload, error, touched }) => {
  const [preview, setPreview] = useState(picture);
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreview(fileUrl);
      onUpload(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".jpg,.jpeg,.png",
    multiple: false,
  });

  return (
    <div className="flex flex-col justify-between">
      <Typography variant="h4" sx={{ color: "white" }}>
        Upload Profile
      </Typography>

      <Box
        style={{
          margin: "30px auto",
          position: "relative",
        }}
        borderRadius="50%"
      >
        <Box
          {...getRootProps()}
          border={
            !preview
              ? `2px dashed ${error ? "white" : "#1976d2"}`
              : "none"
          }
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "180px",
            height: "180px",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: !preview ? "#2A2A2A" : "transparent",
            },
            overflow: "hidden",
            position: "relative",
            backgroundColor: "#2A2A2A",
          }}
        >
          <input {...getInputProps()} />
          {!preview ? (
            <Typography align="center" color="white">
              Drag or click to upload profile picture
            </Typography>
          ) : (
            <img
              src={preview}
              alt="Profile Preview"
              className="border"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          )}
        </Box>
        {error && (
          <Typography
            className="text-center"
            variant="body2"
            color="error"
            mt={2}
          >
            {error}
          </Typography>
        )}
      </Box>
    </div>
  );
};

const UserTypeSelection = ({ cards, handleUpdateUserType, setFieldValue, errors, touched }) => {
  return (
    <div className="flex flex-col justify-between h-auto">
      <div className="text-center mb-6">
        <Typography variant="h3" className="pb-4 text-white">
          Let's get to know more about you
        </Typography>
        <Typography variant="body1" className="text-[#FDD835]">
          Who you are?
        </Typography>
      </div>

      {/* Grid Layout for User Type Cards */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 justify-items-center">
        {cards.map((card, index) => (
          <div
            key={index}
            className="border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100 rounded-xl p-2 w-32 h-32 flex flex-col items-center text-center text-white cursor-pointer hover:bg-[#3A3A3A] transition-all duration-200"
            onClick={()=>{
              setFieldValue("userType", card.value);
              handleUpdateUserType();
            }}
            aria-label={`Select ${card.title} as user type`}
          >
            <div className="flex items-center h-[60%] justify-center">
              {card.icon}
            </div>
            <Typography variant="h6" className="text-white pb-2">
              {card.title}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

export {
  AgeSelection,
  AtmosphereSelection,
  EventVisitsSelection,
  GenresSelection,
  LocationSelection,
  VenueDetails,
  ProfileUpload,
  UserTypeSelection,
};
