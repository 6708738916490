import { EventAvailable, LocationOn } from "@mui/icons-material";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import NoFoodIcon from "@mui/icons-material/NoFood";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  useMediaQuery,
  AccordionDetails,
} from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setEvent } from "state";
import { apiUrl } from "config";
import dayjs from "dayjs";
import UserImage from "components/UserImage";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Photo from "../../assests/images/artist.jpg";

const EventWidget = ({ event }) => {
  const [isAttending, setIsAttending] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [eventExpired, setEventExpired] = useState(false);
  console.log(eventExpired);
  // const loggedInUserId = useSelector((state) => state.user._id);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;
  const primaryLight = palette.primary.light;
  const primaryDark = palette.primary.dark;

  const navigateToSummary = () => {
    if (event.eventType === "LISTING") {
      localStorage.setItem("backUrl", `/explore`);
      navigate(`/explore`);
    } else {
      dispatch(
        setEvent({
          event: event,
        })
      );
      setTimeout(() => {
        localStorage.setItem("backUrl", `/event/${event._id}`);
        navigate(`/ticket/${event._id}`);
      }, 1000);
    }
  };
  const navigateToArtistDetail = (artist) => {
    navigate(`/artist-detail/${artist.profileSlug}`);
  };
  const isEventExpired = async () => {
    const eventDay = dayjs(event.eventDate).startOf("day");
  const currentDay = dayjs().startOf("day");

  console.log("eventDay:", eventDay.toString());
  console.log("currentDay:", currentDay.toString());

  const expired = eventDay.isBefore(currentDay);
  console.log("Event expired status:", expired);

  setEventExpired(expired);
  };

  useEffect(() => {
    isEventExpired();
  }, [event.eventDate]);

  return (
    <div className="mb-10 rounded-[20px] p-5 bg-secBg">
      <div className="flex sm:gap-x-6 gap-x-4 px-2">
        <div className="w-[20px] bg-yellow -mt-5 sm:-mt-5 rounded-b-md"></div>
        <div className="flex flex-col gap-y-2 w-full">
          <div>
            <div className="text-xl sm:text-2xl font-bold mb-2">
              {event.eventName}
            </div>
            <div className="text-base">
              By: {event?.fullName}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between gap-y-4 sm:items-center">
            <div className="flex flex-col gap-y-2">
              <div className="text-yellow text-sm flex flex-wrap flex-row gap-x-2">
                <div className="flex items-center">
                  <LocationOn marginRight="4px" fontSize={"16px"} />
                  <Typography variant="caption" fontSize={"14px"}>
                    {" "}
                    {event.location}
                  </Typography>
                </div>
                <div className="border border-yellow"></div>
                {event.eventType === "LISTING" ? (
                  ""
                ) : (
                  <>
                    <Typography variant="caption" fontSize={"14px"}>
                      ₹{" "}
                      {`${
                        event.ticketPrice != null &&
                        event.ticketPrice != undefined &&
                        event.ticketPrice != "null"
                          ? event.ticketPrice
                          : 0
                      } onwards`}
                    </Typography>
                    <div className="border  border-yellow"></div>
                    <Typography variant="caption" fontSize={"14px"}>
                      {`${event.spotsLeft ? event.spotsLeft : 0} spots left`}
                    </Typography>
                  </>
                )}
              </div>
              <div className="flex flex-wrap flex-row gap-x-2 text-sm">
                <div>
                  {event.eventDate &&
                    dayjs(event.eventDate).format("MMMM DD, YYYY")}
                </div>
                <div className="border border-white"></div>
                <div>
                  {event.eventStartTime &&
                    dayjs(event.eventStartTime).format("hh:mm A")}{" "}
                  to{" "}
                  {event.eventStartTime &&
                    dayjs(event.eventEndTime).format("hh:mm A")}
                </div>
              </div>
            </div>
            <div>
              {(event?.eventType === "GUESTLIST" || event?.eventType === "PACKAGE") && eventExpired === false && (
                <Button
                  data-umami-event-eventdetails={event.eventName}
                  data-umami-event="Event-details-page-book-explore-button"
                  onClick={() => navigateToSummary()}
                  variant="contained"
                  sx={{
                    p: "0.6rem",
                    width: "100px",
                    borderRadius: "10px",
                    backgroundColor: palette.primary.main,
                    color: palette.primary.dark,
                    "&:hover": { color: palette.primary.main },
                  }}
                >
                  Book Now
                </Button>
              )}
              {event?.eventType === "LISTING" && (
                <Button
                  data-umami-event-eventdetails={event.eventName}
                  data-umami-event="Event-details-page-book-explore-button"
                  onClick={() => navigateToSummary()}
                  variant="contained"
                  sx={{
                    p: "0.6rem",
                    width: "100px",
                    borderRadius: "10px",
                    backgroundColor: palette.primary.main,
                    color: palette.primary.dark,
                    "&:hover": { color: palette.primary.main },
                  }}
                >
                  Explore
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        {event.picturePath && (
          <div className="mt-6 border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100">
            <img
              alt="event"
              style={{
                borderRadius: "0.75rem",
                width: "600px",
                height: "300px",
                objectFit: "contain",
                backgroundColor: "black",
              }}
              src={`${event.bannerPath ? event.bannerPath : event.picturePath}`}
            />
          </div>
        )}
      </div>
      <Accordion
        defaultExpanded
        style={{
          borderRadius: "10px",
          marginTop: "1.5rem",
          backgroundColor: "black",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="about-content"
          id="about-header"
        >
          <div className="text-lg font-semibold text-white">About</div>
        </AccordionSummary>

        <AccordionDetails>
          <div className="whitespace-pre-wrap break-words">
            {event.aboutEvent}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion
        style={{
          borderRadius: "10px",
          marginTop: "1rem",
          backgroundColor: "black",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Artist-content"
          id="Artist-header"
        >
          <div className="text-lg font-semibold text-white">Artist</div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex overflow-x-auto gap-4 scrollbar-hide z-50">
            {event.artists.map((artist) => (
              <div
                style={{ cursor: "pointer" }}
                key={artist.id}
                onClick={() => {
                  navigateToArtistDetail(artist);
                }}
                className="flex-shrink-0 w-[80px] h-[80px] sm:w-[100px] sm:h-[100px]  relative"
              >
                <img
                  src={artist.picturePath}
                  onError={(e) => (e.target.src = Photo)}
                  alt={artist?.fullName}
                  className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute inset-0 rounded bg-gradient-to-b from-transparent via-black/30 to-black/90"></div>
                <div className="absolute bottom-2 left-2 text-white !font-semibold text-xs rounded px-1">
                  {artist.nickName && artist.nickName.trim()
                    ? artist.nickName
                    : `${artist?.fullName}`}
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      <div
        className="p-4 flex flex-col gap-y-6"
        style={{
          borderRadius: "10px",
          marginTop: "1rem",
          backgroundColor: "black",
        }}
      >
        <div className="text-lg font-semibold text-white">Facility</div>
        <div className="flex flex-wrap gap-4">
          {event.eventFacility.length > 0 ? (
            <>
              {event.eventFacility.map((ele) => {
                return (
                  <div className="px-6 py-1 h-min rounded-[6px] border border-yellow/50 bg-yellow/20 text-yellow">
                    {ele}
                  </div>
                );
              })}
            </>
          ) : (
            "NA"
          )}

          {/* <div className="px-6 py-1 h-min rounded-[6px] border border-yellow/50 bg-yellow/20 text-yellow">
            Party
          </div>
          <div className="px-6 py-1 h-min rounded-[6px] border border-yellow/50 bg-yellow/20 text-yellow">
            Brunch Parties
          </div> */}
        </div>
        <div className="text-lg font-semibold text-white">Prohibited</div>
        <div className="flex flex-wrap gap-4">
          {event.eventProhibited.length > 0 ? (
            <>
              {event.eventProhibited.map((ele) => {
                return (
                  <div className="px-6 py-1 h-min rounded-[6px] border border-red/50 bg-red/20 text-red">
                    {ele}
                  </div>
                );
              })}
            </>
          ) : (
            "NA"
          )}
          {/* <div className="px-6 py-1 h-min rounded-[6px] border border-red/50 bg-red/20 text-red">
            DJ
          </div>
          <div className="px-6 py-1 h-min rounded-[6px] border border-red/50 bg-red/20 text-red">
            Party
          </div>
          <div className="px-6 py-1 h-min rounded-[6px] border border-red/50 bg-red/20 text-red">
            Brunch Parties
          </div> */}
        </div>
      </div>

      <Accordion
        style={{
          borderRadius: "10px",
          marginTop: "1rem",
          backgroundColor: "black",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="disclaimer-content"
          id="disclaimer-header"
        >
          <div className="text-lg font-semibold text-white">Terms</div>
        </AccordionSummary>

        <AccordionDetails>
          <div className="whitespace-pre-wrap break-words">
            {event.eventDesclaimer}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EventWidget;
