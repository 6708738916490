import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CloseIcon from "@mui/icons-material/Close";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Image from "../../assests/images/Login.jpg";
import Effect from "../../assests/images/effect2.png";
import { apiUrl } from "config";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setLogin } from "state";

const LoginModal = ({ handleClose, open }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [dispTxt, setDispTxt] = useState("Move Counts!");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [validPhone, setValidPhone] = useState(true);
  const [otpSecret, setOtpSecret] = useState(null);
  const [enableRetriggerOtp, setEnableRetriggerOtp] = useState(false);
  const [reTriggerSeconds, setRetriggerSecs] = useState(60);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpType, setOtpType] = useState(false)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (token && user) {
      navigate("/home");
    }

    const interval = setInterval(() => {
      setDispTxt((prev) =>
        prev === "Move Counts!"
          ? "Groove Unites!"
          : prev === "Groove Unites!"
          ? "Beat Resonates!"
          : "Move Counts!"
      );
    }, 2000);
    return () => clearInterval(interval);
  }, [navigate]);

  const handleSwitch = () => {
    setIsLogin(!isLogin);
    setOtpSent(false);
  };

  const handleOtpSend = async () => {
    if (!validPhone || phone.length !== 10) {
      toast.error("Please enter a valid phone number");
      return;
    }

    setLoading(true);
    try {
      if (isLogin) {
        await sendOtp();
        setOtpType(true)
      } else {
        await registerOtp();
        setOtpType(false)
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async () => {

    setLoading(true);
    try {
      if (otpType) {
        await verifyOtp();
      } else {
        await verifyOtp();
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const sendOtp = async () => {
    try {
      const response = await fetch(`${apiUrl}/auth/send-login-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone }),
      });
      const result = await response.json();
      if (!result.error) {
        toast.success(result.message);
        setOtpSecret(result.token);
        setOtpSent(true);
        setEnableRetriggerOtp(false);
        setRetriggerSecs(60);
        let secs = 60;
        const otpInterval = setInterval(() => {
          secs -= 1;
          setRetriggerSecs(secs);
          if (secs === 0) {
            setEnableRetriggerOtp(true);
            clearInterval(otpInterval);
          }
        }, 1000);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Failed to send OTP");
    }
  };

  const verifyOtp = async () => {
    try {
      const response = await fetch(`${apiUrl}/auth/verify-login-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phone, otp, token: otpSecret }),
      });
      const result = await response.json();
      if (!result.error) {
        toast.success(result.message);
        dispatch(setLogin({ user: result.user, token: result.token }));
        navigate(localStorage.getItem("backUrl") || "/home");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Failed to verify OTP");
    }
  };

  const registerOtp = async () => {
    try {
      // Basic email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        toast.error("Please enter a valid email address");
        return;
      }

      const response = await fetch(`${apiUrl}/auth/registerOtp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fullName, email, phone }),
      });
      const result = await response.json();
  
      if (result.error === false) {
        toast.success("Registered successfully. Login to Vybe!");
        toast.success(result.message);
        setOtpSent(true);
        setOtpSecret(result.token);
        setEnableRetriggerOtp(false);
        setRetriggerSecs(60);
  
        let secs = 60;
        const otpInterval = setInterval(() => {
          secs -= 1;
          setRetriggerSecs(secs);
          if (secs === 0) {
            setEnableRetriggerOtp(true);
            clearInterval(otpInterval);
          }
        }, 1000);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("Unable to register. Try again later.");
    }
  };

  const handleRequestClose = () => setOpenConfirm(true);
  const handleNo = () => setOpenConfirm(false);
  const handleConfirmClose = () => {
    setOpenConfirm(false);
    handleClose();
  };

  return (
    <Dialog
      onClose={!otpSent && handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      // fullScreen={fullScreen}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "12px",
          border: "1px solid #808080",
        },
      }}
    >
      <div
        dividers
        className={`flex flex-row ${
          isLogin ? "flex-row" : "flex-row-reverse"
        } w-full p-0`}
      >
        {/* Left (or Right for SignUp) Image Section */}
        <div className="sm:w-[40%] flex justify-center items-center relative sm:block hidden">
          <img
            src={Image}
            alt={isLogin ? "Login Image" : "Sign Up Image"}
            className={`w-full h-full object-cover  ${
              isLogin ? "rounded-l-lg" : "rounded-r-lg"
            } `}
          />
          <div className="absolute bottom-10 left-6 text-white font-bold text-left">
            <Typography variant="body2">Dance the Night Away:</Typography>
            <Typography variant="h6">
              Where Every <span className="text-[#E6C100]">{dispTxt}</span>
            </Typography>
          </div>
        </div>

        {/* Right Content Section */}
        <div
          className={`sm:w-[60%] bg-[#171717] px-10 py-8 text-white ${
            isLogin ? "rounded-r-lg" : "rounded-l-lg"
          }bg-auto bg-center`}
          style={{
            backgroundImage: `url(${Effect})`,
          }}
        >
          {/* Login Form */}
          {isLogin && !otpSent && (
            <div className="h-96 flex justify-between flex-col">
              <div className="flex justify-end">
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="flex flex-col gap-4">
                <Typography variant="h3" className="pb-4">
                  Login
                </Typography>
                <Typography variant="body1">
                  Enter your mobile number
                </Typography>
                <TextField
                  label="Phone"
                  value={phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    const isValid = /^\d{10}$/.test(value);
                    setPhone(value);
                    setValidPhone(isValid);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneAndroidOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!validPhone}
                  helperText={!validPhone && "Please enter a valid phone number"}
                  fullWidth
                />
                <div>
                  <Button
                    onClick={handleOtpSend}
                    disabled={loading}
                    className="py-2 !px-10 mt-2 !bg-[#E6C100] !text-black font-bold rounded-full cursor-pointer"
                  >
                    {loading ? "Sending..." : "Get OTP"}
                  </Button>
                </div>
              </div>
              <div className="flex justify-center mt-4 gap-2">
                <Typography>Don't have an account? </Typography>
                <Typography
                  onClick={handleSwitch}
                  className="text-[#E6C100] cursor-pointer"
                >
                  Sign Up
                </Typography>
              </div>
            </div>
          )}

          {/* Signup Form */}
          {!isLogin && !otpSent && (
            <div className="h-96 relative flex justify-between flex-col">
              <div className="flex flex-col gap-4">
                <button
                  onClick={handleClose}
                  className="absolute top-1 right-1"
                >
                  <CloseIcon />
                </button>
                <Typography variant="h3" className="pb-4">
                  Sign Up
                </Typography>
                <TextField
                  label="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlined />
                      </InputAdornment>
                    ),
                  }}
                  className="w-full"
                />
                <TextField
                  label="Email"
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                  className="w-full"
                />
                <TextField
                  label="Phone"
                  value={phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    const isValid = /^\d{10}$/.test(value);
                    setPhone(value);
                    setValidPhone(isValid);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PhoneAndroidOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  error={!validPhone}
                  helperText={!validPhone && "Please enter a valid phone number"}
                  className="w-full"
                />
                <div>
                  <Button
                    onClick={handleOtpSend}
                    disabled={loading}
                    className="py-2 !px-10 mt-2 !bg-[#E6C100] !text-black font-bold rounded-full cursor-pointer"
                  >
                    {loading ? "Sending..." : "Sign Up"}
                  </Button>
                </div>
              </div>
              <div className="flex justify-center mt-4 gap-2">
                <Typography>Already have an account? </Typography>
                <Typography
                  onClick={handleSwitch}
                  className="text-[#E6C100] cursor-pointer"
                >
                  Sign In
                </Typography>
              </div>
            </div>
          )}

          {/* OTP Verification */}
          {otpSent && (
            <div className="h-52 sm:h-96 relative flex flex-col justify-center">
              <button
                onClick={handleRequestClose}
                className="absolute top-1 right-1"
              >
                <CloseIcon />
              </button>
              <div className="flex flex-col gap-4 justify-center w-full max-w-sm mt-8">
                <Typography variant="body1">
                  Enter the OTP sent to your phone
                </Typography>
                <TextField 
                  label="Enter OTP" 
                  value={otp} 
                  onChange={(e) => setOtp(e.target.value)} 
                  fullWidth 
                  type="tel"
                  inputProps={{ maxLength: 6 }}
                />
                {enableRetriggerOtp ? (
                  <Typography 
                    onClick={sendOtp} 
                    className="underline cursor-pointer"
                  >
                    Resend OTP
                  </Typography>
                ) : (
                  <Typography className="">{`Resend OTP in ${reTriggerSeconds} seconds`}</Typography>
                )}
                <div>
                  <Button 
                    onClick={handleVerifyOtp} 
                    disabled={loading}
                    className="py-2 !px-10 mt-2 !bg-[#E6C100] !text-black font-bold rounded-full cursor-pointer"
                  >
                    {loading ? "Verifying..." : "Verify OTP"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog open={openConfirm}>
        <DialogContent>
          <Typography>Are you sure you want to close?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>No</Button>
          <Button onClick={handleConfirmClose} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

export default LoginModal;