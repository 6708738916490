import React, { Component } from 'react';
import {
    Box,
    TextField,
    Typography,
    Popover,
    Button,
    Grid,
    Chip,
    Stack,
    IconButton,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Divider,
    Autocomplete,
    createFilterOptions,
    Dialog,
    DialogContent,
    DialogTitle
} from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import FlexBetween from "components/FlexBetween";
import Dropzone from "react-dropzone";
import {
    EditOutlined,
    DeleteOutlined
} from "@mui/icons-material";
import { apiUrl } from "./../../config";
import LoadingButton from '@mui/lab/LoadingButton';
import { nanoid } from 'nanoid'
import { toast } from 'react-toastify';

class CreateEventForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventName: null,
            ticketPrice: null,
            location: null,
            aboutEvent: null,
            eventDate: dayjs(),
            eventEndTime: null,
            eventStartTime: null,
            eventDesclaimer: null,
            ticketTypes: [

            ],
            addTicketDetails: {
                name: null,
                description: null,
                noOfTickets: 0,
                ticketPrice: 0,
                type: ''
            },
            coupons: [],
            addCouponDetails: {
                couponName: null,
                discountType: '',
                couponCode: null,
                couponValue: 0,
            },
            eventProhibited: [],
            eventFacility: [],
            artists: [],
            ticketAddPopUp: false,
            couponAddPopUp: false,
            artistAddPopUp: false,
            addArtistDetails: {
                fullName: null,
                firstName: null,
                lastName: null,
                profileSlug: null,
                nickName: null,
                introduction: null,
                genreType: []
            },
            addArtist: {
                name: null,
                description: null
            },
            eventType: '',
            eventImage: null,
            eventBannerImage: null,
            eventFacilities: ['Food Stall', 'Car Parking', 'Swimming', 'Dancing Floor', 'Wifi', 'Drinks', 'Vip Section', 'Vip Table', 'DJ'],
            eventProhibites: ['Phones', 'No Foods', 'No Photos', 'No Pets'],
            loading: false,
            alert: false,
            severity: 'success',
            message: '',
            totalSpots: 0,
            spotsLeft: 0,
            postTags: [],
            tags: [],
            artistsList: [],
            addArtistLoading: false,
            venues: [],
            venuesList: [],
            tagsList: []

        }
        this.myDropzone = React.createRef();
    }

    componentDidMount() {
        console.log("componentDidMount", this.props.theme);
        this.fetchTags();
        if (this.props.eventId != 'new') {
            this.getPostById()
        }
    }
    getPostById = async () => {

        const response = await fetch(`${apiUrl}/posts/${this.props.eventId}/post`, {
            method: "GET",
            headers: { Authorization: `Bearer ${this.props.token}` },
        });
        const data = await response.json();
        console.log("data getPostById", data);
        // let imgurl = data.picturePath;
        // let arr = imgurl.split('/');
        // if (imgurl && imgurl != undefined && imgurl != null && imgurl.includes('http')) {
        //     await fetch(`${imgurl}`, {
        //         method: "GET",
        //     }).then(res => res.blob())
        //         .then((currentBlob) => {
        //             const generateFile = new File([currentBlob], arr[arr.length - 1], {
        //                 type: currentBlob.type,
        //             });
        //             this.myDropzone.addFile(generateFile);
        //         });

        //         // const res = await fetch(imgurl,{headers:{'Access-Control-Allow-Origin':'*'}});
        //         // const blob = await res.blob();
        //         // const file = new File([blob], arr[arr.length - 1], {type: blob.type});
        //         // console.log(file);

        // }
        this.setState({
            userId: data.userId,
            eventName: data.eventName,
            eventType: data.eventType,
            aboutEvent: data.description,
            location: data.location,
            ticketPrice: data.ticketPrice,
            aboutEvent: data.aboutEvent,
            eventDate: dayjs(data.eventDate),
            eventEndTime: dayjs(data.eventEndTime),
            eventStartTime: dayjs(data.eventStartTime),
            eventDesclaimer: data.eventDesclaimer,
            ticketTypes: data.ticketTypes,
            coupons: data?.coupons || [],
            eventProhibited: data.eventProhibited,
            eventFacility: data.eventFacility,
            artists: data.artists,
            totalSpots: data.spotsLeft,
            totalTickets: data.totalTickets,
            postTags: data.postTags,
            venues: data.venues
        })
    };

    fetchTags = async () => {
        const response = await fetch(`${apiUrl}/tags`, {
            method: "POST",
            headers: { Authorization: `Bearer ${this.props.token}`, "Content-Type": "application/json" },
            body: JSON.stringify({ tagType: "ALL" }),
        });
        let tags = await response.json();
        console.log("tags", tags);
        if (this.props.user && this.props.user.role && this.props.user.role == 'VYBEZ_ADMIN') {
            // tags.push({
            //     "tagName": 'Featured',
            //     "tagType": 'Featured'
            // }, {
            //     "tagName": 'Featured-Lite',
            //     "tagType": 'Featured-Lite'
            // })
            tags.push("Featured", "Featured-Lite")

        }
        this.setState({
            tags: tags
        })
    }
    addTicket = () => {
        let ticketDetails = this.state.addTicketDetails;
        ticketDetails.couponId = nanoid();
        ticketDetails.spotsLeft = parseInt(ticketDetails.noOfTickets);
        ticketDetails.ticketPurchaseCount = 0;
        let updatedTickets = this.state.ticketTypes;

        let noOfTickets = parseInt(ticketDetails.noOfTickets)
        let totalTickets = 0;
        this.state.ticketTypes.map((ele) => {
            totalTickets = totalTickets + parseInt(ele.noOfTickets)
        })
        noOfTickets = noOfTickets + totalTickets;
        updatedTickets.push(ticketDetails);
        this.setState({
            ticketTypes: updatedTickets,
            ticketAddPopUp: false,
            totalSpots: noOfTickets,
            totalTickets: noOfTickets,
            addTicketDetails: {}
        })
    }
    addCoupons = () => {
        let couponDetails = this.state.addCouponDetails;
        couponDetails.couponId = nanoid();
        couponDetails.couponUsageCount = 0;

        let updatedCoupons = this.state.coupons;
        updatedCoupons.push(couponDetails);
     
        this.setState({
            coupons: updatedCoupons,
            couponAddPopUp: false,
            addCouponDetails: {}
            
        });
        console.log("state value",this.state)
    };
    addArtist = async () => {
        try {
            if (this.state.addArtistDetails.nickName && this.state.addArtistDetails?.firstName) {

                this.setState({
                    addArtistLoading: true
                }, async () => {
                    const formData = new FormData();
                    formData.append('fullName', this.state.addArtistDetails?.fullName);
                    formData.append('firstName', this.state.addArtistDetails?.firstName);
                    formData.append('lastName', this.state.addArtistDetails?.lastName);
                    formData.append('nickName', this.state.addArtistDetails.nickName);
                    formData.append('picture', this.state.addArtistDetails.picture);
                    formData.append('introduction', this.state.addArtistDetails.introduction);
                    formData.append('genreType', JSON.stringify(this.state.addArtistDetails.genreType));
                    formData.append('type', 'ARTIST');
                    if (this.state.addArtistDetails.picture) {
                        formData.append("picturePath", this.state.addArtistDetails.picture.name ?? "");
                    }
                    const savedUserResponse = await fetch(`${apiUrl}/users/register/artist`, {
                        method: "POST",
                        headers: { Authorization: `Bearer ${this.props.token}` },
                        body: formData,
                    });
                    const savedUser = await savedUserResponse.json();
                    if (savedUser.error == false) {
                        toast.success("Artist added successfully!");
                        let updatedArtists = this.state.artists;
                        if (savedUser.updatedUser.userId == undefined && savedUser.updatedUser.userId == null) {
                            savedUser.updatedUser['userId'] = savedUser.updatedUser._id;
                            delete savedUser.updatedUser._id;
                        }
                        updatedArtists.push(savedUser.updatedUser);
                        console.log("updatedArtists", savedUser.updatedUser, updatedArtists)
                        this.setState({
                            artists: updatedArtists,
                            artistAddPopUp: false,
                            addArtistLoading: false,
                            addArtistDetails: {}
                        })
                    } else {
                        this.setState({
                            artistAddPopUp: false,
                            addArtistLoading: false,
                            addArtistDetails: {}
                        })
                        toast.error(savedUser.message)
                    }
                })
            } else {
                toast.error("Nick name and First Name are mandatory")
                this.setState({
                    addArtistLoading: false,
                    addArtistDetails: {}
                })
            }
        } catch (e) {
            this.setState({
                addArtistLoading: false
            })
            toast.error("Unable to register. Try again later.")
        }
    }
    addEventFacilites = (facility) => {
        let updatedFacilites = this.state.eventFacility;
        let index = updatedFacilites.indexOf(facility)
        if (index != -1) {
            updatedFacilites.splice(index, 1)
        } else {
            updatedFacilites.push(facility)
        }
        this.setState({
            eventFacility: updatedFacilites
        })
    }
    addEventProhibites = (facility) => {
        let updatedProbhites = this.state.eventProhibited;
        let index = updatedProbhites.indexOf(facility)
        if (index != -1) {
            updatedProbhites.splice(index, 1)
        } else {
            updatedProbhites.push(facility)
        }
        this.setState({
            eventProhibited: updatedProbhites
        })
    }
    handlePost = async () => {

        this.setState({
            loading: true
        }, async () => {
            const formData = new FormData();

            formData.append("userId", this.props.userId);
            formData.append("eventName", this.state.eventName);
            formData.append("eventType", this.state.eventType);
            formData.append("description", this.state.aboutEvent);
            formData.append("location", this.state.location);
            formData.append("ticketPrice", this.state.ticketPrice);
            formData.append("aboutEvent", this.state.aboutEvent);
            formData.append("eventDate", this.state.eventDate);
            formData.append("eventEndTime", this.state.eventEndTime);
            formData.append("eventStartTime", this.state.eventStartTime);
            formData.append("eventDesclaimer", this.state.eventDesclaimer);
            formData.append("ticketTypes", JSON.stringify(this.state.ticketTypes));
            formData.append("coupons", JSON.stringify(this.state.coupons));
            formData.append("eventProhibited", JSON.stringify(this.state.eventProhibited));
            formData.append("eventFacility", JSON.stringify(this.state.eventFacility));
            formData.append("postType", "EVENT");
            formData.append("artists", JSON.stringify(this.state.artists));
            formData.append("spotsLeft", this.state.totalSpots);
            formData.append("totalTickets", this.state.totalTickets);
            formData.append("postTags", JSON.stringify(this.state.postTags));
            formData.append("venues", JSON.stringify(this.state.venues));

            if (this.state.eventImage) {
                formData.append("picture", this.state.eventImage);
                formData.append("picturePath", this.state.eventImage.name);
            }
            if (this.state.eventBannerImage) {
                formData.append("banner", this.state.eventBannerImage);
                formData.append("bannerPath", this.state.eventBannerImage.name);
            }
            try {
                const response = await fetch(`${apiUrl}/posts`, {
                    method: "POST",
                    headers: { Authorization: `Bearer ${this.props.token}` },
                    body: formData,
                });
                const posts = await response.json();
                console.log("posts", posts)
                this.setState({
                    eventImage: null,
                    loading: false
                }, () => {
                    this.props.navigateBasedOnRoute(`home`)
                })
            } catch (e) {
                this.setState({
                    alert: true,
                    message: 'Unable to create event try again.',
                    severity: 'error'
                })
            }


        })

    };
    handleClose = () => {
        this.setState({
            alert: false
        })
    }
    addEventTags = (tag) => {
        let updatedPostTags = this.state.postTags;
        let index = updatedPostTags.indexOf(tag)
        if (index != -1) {
            updatedPostTags.splice(index, 1)
        } else {
            updatedPostTags.push(tag)
        }
        this.setState({
            postTags: updatedPostTags
        })
    }
    filterOptions = createFilterOptions({
        matchFrom: 'start',
        stringify: (option) => option.title,
    });

    fetchArtists = async (name) => {
        const response = await fetch(`${apiUrl}/users/artists-search/${name}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${this.props.token}`, "Content-Type": "application/json" },

        });
        let respData = await response.json();
        let artists = respData.artists || []

        this.setState({
            artistsList: artists
        })
    }
    onArtistChange = async (event, values) => {
        console.log("event, values", event, values)
        let updatedValues = values.map((ele) => {
            if (ele.userId == undefined && ele.userId == null) {
                ele['userId'] = ele._id;
                delete ele._id;
            }
            return ele;
        })
        console.log("onArtistChange", updatedValues)
        this.setState({
            artists: updatedValues
        })
    }
    fetchVenues = async (name) => {
        const response = await fetch(`${apiUrl}/users/venues-search/${name}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${this.props.token}`, "Content-Type": "application/json" },

        });
        let respData = await response.json();
        let venues = respData.venues || []
        console.log("venues", venues)

        this.setState({
            venuesList: venues
        })
    }
    onVenuChange = async (event, values) => {
        console.log("event, values", event, values)
        let updatedValues = values.map((ele) => {
            if (ele.userId == undefined && ele.userId == null) {
                ele['userId'] = ele._id;
                delete ele._id;
            }
            return ele;
        })
        console.log("onVenuChange", updatedValues)
        this.setState({
            venues: updatedValues
        })
    }
    fetchTagByName = async (name) => {
        const response = await fetch(`${apiUrl}/tags/search/${name}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${this.props.token}`, "Content-Type": "application/json" },

        });
        let respData = await response.json();
        let tags = respData.tags || []

        this.setState({
            tagsList: tags
        })
    }
    render() {
        return (
            <WidgetWrapper marginBottom={'4rem'}>

                <Box
                    display="grid"
                    gap="20px"
                    gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                    sx={{
                        "& > div": { gridColumn: this.props.theme.isNonMobileScreens ? undefined : "span 6" },
                    }}
                >
                    <Typography
                        textAlign={"left"}
                        fontWeight={400}
                        fontSize="20px"
                        gridColumn="span 6"
                        color="primary"
                    >
                        Create Event
                    </Typography>
                    <TextField
                        label="Event Name"
                        onChange={(e) => {
                            this.setState({
                                eventName: e.target.value
                            })
                        }}
                        value={this.state.eventName}
                        name="eventName"
                        sx={{ gridColumn: "span 6" }}
                    />
                    <FormControl variant="filled" sx={{ gridColumn: "span 6" }}>
                        <InputLabel id="demo-simple-select-filled-label">Event Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={this.state.eventType}
                            onChange={(e) => {
                                this.setState({
                                    eventType: e.target.value
                                })
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value={'GUESTLIST'}>Guest List</MenuItem>
                            <MenuItem value={'PACKAGE'}>Package</MenuItem>
                            {(this.props.user && this.props.user.role && this.props.user.role == 'VYBEZ_ADMIN') ?
                                <MenuItem value={'LISTING'}>Listing</MenuItem>
                                : ''}

                        </Select>
                    </FormControl>
                    {/* For aspect ratio 1:1 Default for featured */}
                    <Dropzone
                        ref={this.myDropzone}
                        acceptedFiles=".jpg,.jpeg,.png"
                        multiple={false}
                        onDrop={(acceptedFiles) => this.setState({ eventImage: acceptedFiles[0] })}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <FlexBetween gridColumn="span 6">
                                <Box
                                    gridColumn="span 6"
                                    {...getRootProps()}
                                    border={`2px dashed ${this.props.theme.palette.primary.main}`}
                                    p="1rem"
                                    width="100%"
                                    sx={{ "&:hover": { cursor: "pointer" } }}
                                >
                                    <input {...getInputProps()} />
                                    {!this.state.eventImage ? (
                                        <p>Add Event Poster With Aspect Ratio 1:1 Here</p>
                                    ) : (
                                        <FlexBetween>
                                            <Typography>{this.state.eventImage.name} (Event Poster With Aspect Ratio 1:1)</Typography>
                                            <EditOutlined />
                                        </FlexBetween>
                                    )}
                                </Box>
                                {this.state.eventImage && (
                                    <IconButton
                                        onClick={() => this.setState({ eventImage: null })}
                                        sx={{ width: "15%" }}
                                    >
                                        <DeleteOutlined />
                                    </IconButton>
                                )}
                            </FlexBetween>
                        )}
                    </Dropzone>
                    {/* For aspect ratio 1:2 Banner */}
                    <Dropzone
                        acceptedFiles=".jpg,.jpeg,.png"
                        multiple={false}
                        onDrop={(acceptedFiles) => this.setState({ eventBannerImage: acceptedFiles[0] })}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <FlexBetween gridColumn="span 6">
                                <Box
                                    gridColumn="span 6"
                                    {...getRootProps()}
                                    border={`2px dashed ${this.props.theme.palette.primary.main}`}
                                    p="1rem"
                                    width="100%"
                                    sx={{ "&:hover": { cursor: "pointer" } }}
                                >
                                    <input {...getInputProps()} />
                                    {!this.state.eventBannerImage ? (
                                        <p>Add Event Poster With Aspect Ratio 1:2 For Banner Here</p>
                                    ) : (
                                        <FlexBetween>
                                            <Typography>{this.state.eventBannerImage.name} (Event Poster With Aspect Ratio 1:2)</Typography>
                                            <EditOutlined />
                                        </FlexBetween>
                                    )}
                                </Box>
                                {this.state.eventBannerImage && (
                                    <IconButton
                                        onClick={() => this.setState({ eventBannerImage: null })}
                                        sx={{ width: "15%" }}
                                    >
                                        <DeleteOutlined />
                                    </IconButton>
                                )}
                            </FlexBetween>
                        )}
                    </Dropzone>
                    <TextField
                        label="Event Location"
                        onChange={(e) => {
                            this.setState({
                                location: e.target.value
                            })
                        }}
                        value={this.state.location}
                        name="location"
                        sx={{ gridColumn: "span 6" }}
                    />
                    <Autocomplete
                        multiple
                        sx={{ gridColumn: "span 6" }}
                        id="size-small-outlined-multi"
                        size="small"
                        options={this.state.venuesList}
                        getOptionLabel={(option) => ((option.venueName != undefined && option.venueName != null && option.venueName != '') ? option.venueName : `${option?.fullName}`)}
                        defaultValue={this.state.venues}
                        onChange={this.onVenuChange}
                        renderInput={(params) => (
                            <TextField {...params} onChange={(e) => {
                                this.fetchVenues(e.target.value)
                            }} label="Tag Venue" placeholder="Venue" />
                        )}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Event Date"
                            value={this.state.eventDate}
                            onChange={(newValue) => {
                                this.setState({
                                    eventDate: newValue
                                })
                            }}
                            sx={{ gridColumn: "span 6" }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Event Start Time"
                            value={this.state.eventStartTime}
                            onChange={(newValue) => {
                                this.setState({
                                    eventStartTime: newValue
                                })
                            }}
                            sx={{ gridColumn: "span 3" }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label="Event End Time"
                            value={this.state.eventEndTime}
                            onChange={(newValue) => {
                                this.setState({
                                    eventEndTime: newValue
                                })
                            }}
                            sx={{ gridColumn: "span 3" }}
                        />
                    </LocalizationProvider>
                    <TextField
                        label="Ticket Price(In Rs)"
                        onChange={(e) => {
                            this.setState({
                                ticketPrice: e.target.value
                            })
                        }}
                        value={this.state.ticketPrice}
                        name="ticketPrice"
                        sx={{ gridColumn: "span 6" }}
                        placeholder='1000-2000'
                    />
                    <TextField
                        id="outlined-multiline-static"
                        label="About Event"
                        multiline
                        rows={4}
                        onChange={(e) => {
                            this.setState({
                                aboutEvent: e.target.value
                            })
                        }}
                        value={this.state.aboutEvent}
                        name='aboutEvent'
                        sx={{ gridColumn: "span 6" }}
                    />
                    <TextField
                        id="outlined-multiline-static"
                        label="Disclaimer"
                        multiline
                        rows={4}
                        onChange={(e) => {
                            this.setState({
                                eventDesclaimer: e.target.value
                            })
                        }}
                        value={this.state.eventDesclaimer}
                        name='eventDesclaimer'
                        sx={{ gridColumn: "span 6" }}
                    />
                    <FlexBetween gap="1.5rem" sx={{ gridColumn: "span 6" }}>
                        <Typography
                            textAlign={"left"}
                            fontWeight={400}
                            fontSize="18px"
                            gridColumn="span 6"
                            color="primary"
                        >
                            Ticket Categories<sub style={{ verticalAlign: "super", color: 'red' }}>*</sub>
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => {
                            this.setState({
                                ticketAddPopUp: true
                            })
                        }}>
                            Add Plan
                        </Button>
                    </FlexBetween>
                    {this.state.ticketTypes.map((ele) => {
                        return (
                            <Box
                                sx={{ marginBottom: '5px', gridColumn: "span 6", backgroundColor: '#5b5b5b', borderRadius: '4px', padding: '5px' }}>
                                <Typography variant="h4" color='primary' pb="0.5rem">
                                    {ele.name}
                                </Typography>
                                <Typography style={{ fontSize: "12px" }} pb="0.75rem">
                                    {ele.description}
                                </Typography>
                                <Typography pb="0.75rem">Total Spots: {ele.noOfTickets}</Typography>
                                <Typography pb="0.75rem">Ticket Price: Rs.{ele.ticketPrice}</Typography>
                            </Box>
                        )
                    })}

                    <FlexBetween gap="1.5rem" sx={{ gridColumn: "span 6" }}>
                        <Typography
                            textAlign={"left"}
                            fontWeight={400}
                            fontSize="18px"
                            gridColumn="span 6"
                            color="primary"
                        >
                            Add Coupon
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => {
                            this.setState({
                                couponAddPopUp: true
                            })
                        }}>
                            Add
                        </Button>
                    </FlexBetween>
                    {this.state?.coupons?.map((ele) => {
                        return (
                            <Box
                                sx={{ marginBottom: '5px', gridColumn: "span 6", backgroundColor: '#5b5b5b', borderRadius: '4px', padding: '5px' }}>
                                <Typography variant="h4" color='primary' pb="0.5rem">
                                    {ele.couponName}
                                </Typography>
                                <Typography pb="0.75rem">Discoun Type: {ele.discountType}</Typography>
                                <Typography pb="0.75rem">Coupon Code: {ele.couponCode}</Typography>
                                <Typography pb="0.75rem">Coupon Value: Rs.{ele.couponValue}</Typography>
                            </Box>
                        )
                    })}

                    <FlexBetween gap="1.5rem" sx={{ gridColumn: "span 6" }}>
                        <Typography
                            textAlign={"left"}
                            fontWeight={400}
                            fontSize="18px"
                            gridColumn="span 6"
                            color="primary"
                        >
                            Artists<sub style={{ verticalAlign: "super", color: 'red' }}>*</sub>
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => {
                            this.setState({
                                artistAddPopUp: true
                            })
                        }}>
                            Add Artist
                        </Button>
                    </FlexBetween>
                    {this.state.artists.map((ele) => {
                        return (
                            <Box
                                sx={{ marginBottom: '5px', gridColumn: "span 6", backgroundColor: '#5b5b5b', borderRadius: '4px', padding: '5px' }}>
                                <Typography variant="h4" color='primary' pb="0.5rem">
                                    {(ele.nickName != undefined && ele.nickName != null && ele.nickName != '') ? ele.nickName : `${ele?.fullName}`}
                                </Typography>
                                <Typography style={{ fontSize: "12px" }} pb="0.75rem">
                                    {ele.description}
                                </Typography>
                            </Box>
                        )
                    })}
                    <Typography
                        textAlign={"left"}
                        fontWeight={400}
                        fontSize="18px"
                        gridColumn="span 6"
                        color="primary"
                    >
                        Facilities<sub style={{ verticalAlign: "super", color: 'red' }}>*</sub>
                    </Typography>
                    <Stack
                        style={{ width: "100%", marginTop: "2px" }}
                        direction="row"
                        flexWrap={"wrap"}
                        alignItems={"center"}
                        justifyContent="center"
                        gap={"12px"}
                        gridColumn="span 6"
                    >
                        {this.state.eventFacilities.map((value) => (
                            <Chip
                                key={value}
                                onClick={() => this.addEventFacilites(value)}
                                style={{
                                    fontSize: "16px",
                                    padding: "1rem 0.5rem",
                                    border: `0.5px solid ${this.props.theme.palette.primary.main}`,
                                    backgroundColor: this.state.eventFacility.includes(value)
                                        ? this.props.theme.palette.primary.main
                                        : this.props.theme.palette.grey[900],
                                    color: this.state.eventFacility.includes(value) ? this.props.theme.palette.grey[900] : "white",
                                    cursor: "pointer",
                                }}
                                label={value}
                                variant="outlined"
                            />
                        ))}
                    </Stack>
                    <Typography
                        textAlign={"left"}
                        fontWeight={400}
                        fontSize="18px"
                        gridColumn="span 6"
                        color="primary"
                    >
                        Prohibited At Event<sub style={{ verticalAlign: "super", color: 'red' }}>*</sub>
                    </Typography>
                    <Stack
                        style={{ width: "100%", marginTop: "2px" }}
                        direction="row"
                        flexWrap={"wrap"}
                        alignItems={"center"}
                        justifyContent="center"
                        gap={"12px"}
                        gridColumn="span 6"
                    >
                        {this.state.eventProhibites.map((value) => (
                            <Chip
                                key={value}
                                onClick={() => this.addEventProhibites(value)}
                                style={{
                                    fontSize: "16px",
                                    padding: "1rem 0.5rem",
                                    border: `0.5px solid ${this.props.theme.palette.primary.main}`,
                                    backgroundColor: this.state.eventProhibited.includes(value)
                                        ? this.props.theme.palette.primary.main
                                        : this.props.theme.palette.grey[900],
                                    color: this.state.eventProhibited.includes(value) ? this.props.theme.palette.grey[900] : "white",
                                    cursor: "pointer",
                                }}
                                label={value}
                                variant="outlined"
                            />
                        ))}
                    </Stack>
                    <Typography
                        textAlign={"left"}
                        fontWeight={400}
                        fontSize="18px"
                        gridColumn="span 6"
                        color="primary"
                    >
                        Tag Your Event<sub style={{ verticalAlign: "super", color: 'red' }}>*</sub>
                    </Typography>
                    <Stack
                        style={{ width: "100%", marginTop: "2px", marginBottom: '5px', overflow: 'scroll', height: '30vh' }}
                        direction="row"
                        flexWrap={"wrap"}
                        alignItems={"center"}
                        justifyContent="center"
                        gap={"12px"}
                        gridColumn="span 6"
                    >
                        {this.state.tags.map((value) => (
                            <Chip
                                key={value}
                                onClick={() => this.addEventTags(value)}
                                style={{
                                    fontSize: "16px",
                                    padding: "1rem 0.5rem",
                                    border: `0.5px solid ${this.props.theme.palette.primary.main}`,
                                    backgroundColor: this.state.postTags.includes(value)
                                        ? this.props.theme.palette.primary.main
                                        : this.props.theme.palette.grey[900],
                                    color: this.state.postTags.includes(value) ? this.props.theme.palette.grey[900] : "white",
                                    cursor: "pointer",
                                }}
                                label={value}
                                variant="outlined"
                            />
                        ))}
                    </Stack>
                    <LoadingButton
                        disabled={this.state.postTags.length == 0 ? true : false}
                        sx={{ gridColumn: "span 6" }}
                        color="primary"
                        onClick={this.handlePost}
                        loading={this.state.loading}
                        loadingIndicator="Creating..."
                        variant="contained"
                    >
                        <span>Create Event</span>
                    </LoadingButton>

                </Box>
                {/* Pop Over for the Adding ticket */}

                <Popover
                    open={this.state.ticketAddPopUp}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "center",
                        horizontal: "center",
                    }}
                    onClose={() => {
                        this.setState({
                            ticketAddPopUp: false
                        })
                    }}
                >
                    <Box p="2rem" maxWidth="600px" style={{ position: "relative" }}
                        display="grid"
                        gap="20px"
                        gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                        sx={{
                            "& > div": { gridColumn: this.props.theme.isNonMobileScreens ? undefined : "span 6" },
                        }}>
                        <FormControl variant="filled" sx={{ gridColumn: "span 12" }}>
                            <InputLabel id="demo-simple-select-filled-label">Ticket Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={this.state.addTicketDetails.type}
                                onChange={(e) => {
                                    let updatedAddTicket = this.state.addTicketDetails
                                    updatedAddTicket.type = e.target.value
                                    this.setState({
                                        addTicketDetails: updatedAddTicket
                                    })
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={'GUESTLIST'}>Guest List</MenuItem>
                                <MenuItem value={'PACKAGE'}>Package</MenuItem>
                            </Select>
                        </FormControl>

                        {this.state.addTicketDetails.type == 'GUESTLIST' ?
                            <>
                                <TextField
                                    label="Name"
                                    onChange={(e) => {
                                        let updatedAddTicket = this.state.addTicketDetails
                                        updatedAddTicket.name = e.target.value
                                        this.setState({
                                            addTicketDetails: updatedAddTicket
                                        })
                                    }}
                                    value={this.state.addTicketDetails.name}
                                    name="name"
                                    sx={{ gridColumn: "span 12" }}
                                />
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    onChange={(e) => {
                                        let updatedAddTicket = this.state.addTicketDetails
                                        updatedAddTicket.description = e.target.value
                                        this.setState({
                                            addTicketDetails: updatedAddTicket
                                        })
                                    }}
                                    value={this.state.addTicketDetails.description}
                                    name='description'
                                    sx={{ gridColumn: "span 12" }}
                                />
                                <TextField
                                    label="No. Of Slots"
                                    onChange={(e) => {
                                        let updatedAddTicket = this.state.addTicketDetails
                                        updatedAddTicket.noOfTickets = e.target.value
                                        this.setState({
                                            addTicketDetails: updatedAddTicket
                                        })
                                    }}
                                    value={this.state.addTicketDetails.noOfTickets}
                                    name="noOfTickets"
                                    sx={{ gridColumn: "span 12" }}
                                    placeholder='100'
                                />
                                {/* <TextField
                                    label="Price"
                                    onChange={(e) => {
                                        let updatedAddTicket = this.state.addTicketDetails
                                        updatedAddTicket.ticketPrice = e.target.value
                                        this.setState({
                                            addTicketDetails: updatedAddTicket
                                        })
                                    }}
                                    value={this.state.addTicketDetails.ticketPrice}
                                    name="price"
                                    sx={{ gridColumn: "span 12" }}
                                    placeholder='1000'
                                /> */}
                            </>
                            :
                            ""
                        }
                        {this.state.addTicketDetails.type == 'PACKAGE' ?
                            <>
                                <TextField
                                    label="Name"
                                    onChange={(e) => {
                                        let updatedAddTicket = this.state.addTicketDetails
                                        updatedAddTicket.name = e.target.value
                                        this.setState({
                                            addTicketDetails: updatedAddTicket
                                        })
                                    }}
                                    value={this.state.addTicketDetails.name}
                                    name="name"
                                    sx={{ gridColumn: "span 12" }}
                                />
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Description"
                                    multiline
                                    rows={4}
                                    onChange={(e) => {
                                        let updatedAddTicket = this.state.addTicketDetails
                                        updatedAddTicket.description = e.target.value
                                        this.setState({
                                            addTicketDetails: updatedAddTicket
                                        })
                                    }}
                                    value={this.state.addTicketDetails.description}
                                    name='description'
                                    sx={{ gridColumn: "span 12" }}
                                />
                                <TextField
                                    label="No. Of Tickets"
                                    onChange={(e) => {
                                        let updatedAddTicket = this.state.addTicketDetails
                                        updatedAddTicket.noOfTickets = e.target.value
                                        this.setState({
                                            addTicketDetails: updatedAddTicket
                                        })
                                    }}
                                    value={this.state.addTicketDetails.noOfTickets}
                                    name="noOfTickets"
                                    sx={{ gridColumn: "span 12" }}
                                    placeholder='100'
                                />
                                <TextField
                                    label="Price"
                                    onChange={(e) => {
                                        let updatedAddTicket = this.state.addTicketDetails
                                        updatedAddTicket.ticketPrice = e.target.value
                                        this.setState({
                                            addTicketDetails: updatedAddTicket
                                        })
                                    }}
                                    value={this.state.addTicketDetails.ticketPrice}
                                    name="price"
                                    sx={{ gridColumn: "span 12" }}
                                    placeholder='1000'
                                />
                            </> : ''}

                        <FlexBetween gap="1rem" alignItems='right' sx={{ gridColumn: "span 6", alignItems: 'right' }}>
                            <Button variant="contained" onClick={this.addTicket} color="primary">
                                Add
                            </Button>
                            <Button variant="contained" color="primary"
                                onClick={() => {
                                    this.setState({
                                        ticketAddPopUp: false
                                    })
                                }}>
                                Cancel
                            </Button>
                        </FlexBetween>
                    </Box>
                </Popover>
                {/* Pop Over for the Artist ticket */}
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={this.state.couponAddPopUp}
                    onClose={() => {
                        this.setState({
                            couponAddPopUp: false
                        })
                    }}
                >
                    <DialogTitle>Add Coupon</DialogTitle>
                    <DialogContent>
                        <Box p="2rem" maxWidth="600px" style={{ position: "relative" }}
                            display="grid"
                            gap="20px"
                            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: this.props.theme.isNonMobileScreens ? undefined : "span 6" },
                            }}>
                            <TextField
                                label="Coupon Name"
                                required={true}
                                onChange={(e) => {
                                    let updatedcoupons = this.state.addCouponDetails
                                    updatedcoupons.couponName = e.target.value
                                    this.setState({
                                        addCouponDetails: updatedcoupons
                                    })
                                }}
                                value={this.state.addCouponDetails.couponName}
                                name="couponName"
                                sx={{ gridColumn: "span 12" }}
                            />
                            <FormControl variant="filled" sx={{ gridColumn: "span 12" }}>
                            <InputLabel id="demo-simple-select-filled-label">Discount Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={this.state.addCouponDetails.discountType}
                                onChange={(e) => {
                                    let updatedcoupons = this.state.addCouponDetails
                                    updatedcoupons.discountType = e.target.value
                                    this.setState({
                                        addCouponDetails: updatedcoupons
                                    })
                                }}
                            >
                                <MenuItem value={'Percentage'}>Percentage</MenuItem>
                                <MenuItem value={'Amount'}>Amount</MenuItem>
                            </Select>
                            </FormControl>
                            <TextField
                                label="Coupon Code"
                                required={true}
                                onChange={(e) => {
                                    // Convert the input to uppercase
                                    const updatedValue = e.target.value.toUpperCase();
                                    let updatedCoupons = this.state.addCouponDetails;
                                    updatedCoupons.couponCode = updatedValue;
                                    this.setState({
                                    addCouponDetails: updatedCoupons,
                                    });
                                }}
                                value={this.state.addCouponDetails.couponCode}
                                name="couponCode"
                                sx={{ gridColumn: "span 12" }}
                            />
                            <TextField
                                label="Coupon Value"
                                required={true}
                                onChange={(e) => {
                                    let updatedcoupons = this.state.addCouponDetails
                                    updatedcoupons.couponValue = e.target.value
                                    this.setState({
                                        addCouponDetails: updatedcoupons
                                    })
                                }}
                                value={this.state.addCouponDetails.couponValue}
                                name="couponValue"
                                sx={{ gridColumn: "span 12" }}
                            />
                            
                            {/* <br></br> */}

                            <FlexBetween gap="1rem" alignItems='right' sx={{ gridColumn: "span 12", alignItems: 'right' }}>
                                {/* <Button variant="contained" onClick={this.addArtist} color="primary">
                                Create
                            </Button> */}
                                <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({
                                            couponAddPopUp: false
                                        })
                                    }}>
                                    Cancel
                                </Button>
                                <LoadingButton
                                    // disabled={(this.state.coupons.couponName === undefined || this.state.coupons.discountType == null || this.state.coupons.couponValue == '') ? true : false}
                                    sx={{ gridColumn: "span 6" }}
                                    color="primary"
                                    onClick={this.addCoupons}
                                    variant="contained"
                                >
                                    <span>Create </span>
                                </LoadingButton>
                            </FlexBetween>
                        </Box>
                    </DialogContent>
                </Dialog>


                {/* Pop Over for the Artist ticket */}
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={this.state.artistAddPopUp}
                    onClose={() => {
                        this.setState({
                            artistAddPopUp: false
                        })
                    }}
                >
                    <DialogTitle>Add Artists</DialogTitle>
                    <DialogContent>
                        <Box p="2rem" maxWidth="600px" style={{ position: "relative" }}
                            display="grid"
                            gap="20px"
                            gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: this.props.theme.isNonMobileScreens ? undefined : "span 6" },
                            }}>

                            <Autocomplete
                                multiple
                                sx={{ gridColumn: "span 12" }}
                                id="size-small-outlined-multi"
                                size="small"
                                options={this.state.artistsList}
                                getOptionLabel={(option) => ((option.nickName != undefined && option.nickName != null && option.nickName != '') ? option.nickName : `${option?.fullName}`)}
                                defaultValue={this.state.artists}
                                onChange={this.onArtistChange}
                                renderInput={(params) => (
                                    <TextField {...params} onChange={(e) => {
                                        this.fetchArtists(e.target.value)
                                    }} label="Search Artists" placeholder="Artist" />
                                )}
                            />
                            <div style={{ textAlign: 'right', float: 'right', width: '100%', gridColumn: "span 12" }}>
                                <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({
                                            artistAddPopUp: false
                                        })
                                    }}>
                                    Add
                                </Button>
                            </div>

                            <Divider sx={{ gridColumn: "span 12" }}>OR Create Artist</Divider>
                            <TextField
                                label="Nick Name"
                                required={true}
                                onChange={(e) => {
                                    let updatedAddArtist = this.state.addArtistDetails
                                    updatedAddArtist.nickName = e.target.value
                                    this.setState({
                                        addArtistDetails: updatedAddArtist
                                    })
                                }}
                                value={this.state.addArtistDetails.nickName}
                                name="nickName"
                                sx={{ gridColumn: "span 12" }}
                            />
                            <TextField
                                label="First Name"
                                required={true}
                                onChange={(e) => {
                                    let updatedAddArtist = this.state.addArtistDetails
                                    updatedAddArtist.firstName = e.target.value
                                    this.setState({
                                        addArtistDetails: updatedAddArtist
                                    })
                                }}
                                value={this.state.addArtistDetails?.firstName}
                                name="firstName"
                                sx={{ gridColumn: "span 12" }}
                            />
                            <TextField
                                label="Last Name"
                                onChange={(e) => {
                                    let updatedAddArtist = this.state.addArtistDetails
                                    updatedAddArtist.lastName = e.target.value
                                    this.setState({
                                        addArtistDetails: updatedAddArtist
                                    })
                                }}
                                value={this.state.addArtistDetails.lastName}
                                name="lastName"
                                sx={{ gridColumn: "span 12" }}
                            />
                            <Dropzone

                                acceptedFiles=".jpg,.jpeg,.png"
                                multiple={false}
                                onDrop={(acceptedFiles) => {
                                    // setFieldValue("picture", acceptedFiles[0]);
                                    let updatedAddArtist = this.state.addArtistDetails
                                    updatedAddArtist.picture = acceptedFiles[0]
                                    this.setState({
                                        addArtistDetails: updatedAddArtist
                                    })
                                }}
                            >
                                {({ getRootProps, getInputProps }) => (
                                    <FlexBetween gridColumn="span 12">
                                        <Box
                                            {...getRootProps()}
                                            border={`2px dashed ${this.props.theme.palette.primary.main}`}
                                            p="1rem"
                                            width="100%"
                                            sx={{
                                                "&:hover": { cursor: "pointer" },
                                                border: `2px dashed ${this.props.theme.palette.primary.main}`,
                                                gridColumn: "span 12",

                                            }}
                                        >
                                            <input {...getInputProps()} />
                                            {!this.state.addArtistDetails.picture ? (
                                                <Typography>{"Add Picture Here"}</Typography>
                                            ) : (
                                                <FlexBetween>
                                                    <Typography>{this.state.addArtistDetails.picture.name}</Typography>
                                                    {/* <EditOutlinedIcon /> */}
                                                </FlexBetween>
                                            )}
                                        </Box>
                                        {this.state.eventImage && (
                                            <IconButton
                                                onClick={() => {
                                                    let updatedAddArtist = this.state.addArtistDetails
                                                    updatedAddArtist.picture = null
                                                    this.setState({
                                                        addArtistDetails: updatedAddArtist
                                                    })
                                                }}

                                                sx={{ width: "15%" }}
                                            >
                                                <DeleteOutlined />
                                            </IconButton>
                                        )}
                                    </FlexBetween>

                                )}
                            </Dropzone>
                            <Autocomplete
                                multiple
                                sx={{ gridColumn: "span 12" }}
                                id="size-small-outlined-multi"
                                size="small"
                                options={this.state.tagsList}
                                getOptionLabel={(option) => option.tagName}
                                onChange={(e, value) => {
                                    let updatedAddArtist = this.state.addArtistDetails;
                                    updatedAddArtist.genreType = value.map((option) => option.tagName);
                                    this.setState({
                                        addArtistDetails: updatedAddArtist
                                    })
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} onChange={(e) => {
                                        this.fetchTagByName(e.target.value)
                                    }} label="Search genre" placeholder="Genres" />
                                )}
                            />
                            <TextField
                                id="outlined-multiline-static"
                                label="About Artist"
                                multiline
                                rows={4}
                                onChange={(e) => {
                                    let updatedAddArtist = this.state.addArtistDetails
                                    updatedAddArtist.introduction = e.target.value
                                    this.setState({
                                        addArtistDetails: updatedAddArtist
                                    })
                                }}
                                value={this.state.addArtistDetails.introduction}
                                name='introduction'
                                sx={{ gridColumn: "span 12" }}
                            />


                            {/* <br></br> */}

                            <FlexBetween gap="1rem" alignItems='right' sx={{ gridColumn: "span 12", alignItems: 'right' }}>
                                {/* <Button variant="contained" onClick={this.addArtist} color="primary">
                                Create
                            </Button> */}
                                <LoadingButton
                                    disabled={(this.state.addArtistDetails.nickName == undefined || this.state.addArtistDetails.nickName == null || this.state.addArtistDetails.nickName == '') ? true : false}
                                    sx={{ gridColumn: "span 6" }}
                                    color="primary"
                                    onClick={this.addArtist}
                                    loading={this.state.addArtistLoading}
                                    loadingIndicator="Adding..."
                                    variant="contained"
                                >
                                    <span>Create </span>
                                </LoadingButton>
                                <Button variant="contained" color="primary"
                                    onClick={() => {
                                        this.setState({
                                            artistAddPopUp: false
                                        })
                                    }}>
                                    Cancel
                                </Button>
                            </FlexBetween>
                        </Box>
                    </DialogContent>
                </Dialog>
            </WidgetWrapper>
        );
    }
}



export default CreateEventForm;