/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from "react";
import { Typography, Button, IconButton, TextField, CircularProgress } from "@mui/material";
import {
  FavoriteOutlined,
  FavoriteBorderOutlined,
  ChatBubbleOutlineOutlined,
  Send as SendIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { setPost } from "state";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { apiUrl } from "./../../config";
import { useNavigate } from "react-router-dom";
import Friend from "components/Friend";
import DefaultProfilePic from "../../assests/images/default-profile.png";

const PostWidget = ({
  postId,
  postUserId,
  name,
  location,
  userPicturePath,
  bannerPath,
  picturePath,
  eventType,
  postType,
  description,
  eventDate,
  likes = {},
  commentsCount,
  isNonMobileScreens,
}) => {
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isComments, setIsComments] = useState(false);
  const [reload, setReload] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreComments, setHasMoreComments] = useState(false);
  const [eventExpired, setEventExpired] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const commentSectionRef = useRef(null);
  const isLiked = Boolean(likes[loggedInUserId]);
  const likeCount = Object.keys(likes).length;

  const { palette } = useTheme();
  const primary = palette.primary.main;

  const getComments = async (page, postId) => {
    setLoadingComments(true);
    try {
      const response = await fetch(
        `${apiUrl}/posts/comments?page=${page}&limit=5&postId=${postId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to retrieve comments");
      }
      const data = await response.json();
      if (loadMore) {
        setComments((prevComments) => [...prevComments, ...data.comments]);
        setLoadMore(false);
      } else {
        setComments(data.comments);
      }
      setHasMoreComments(data.hasMore);
    } catch (error) {
      toast.error(error.message || "An error occurred while fetching comments");
    } finally{
      setLoadingComments(false);
    }
  };

  const handleLoadMore = () => {
    setLoadMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleComment = () => {
    setCurrentPage(1);
    setIsComments(!isComments);
  };

  const handleSubmitComment = async () => {
    try {
      if (!newComment.trim()) {
        toast.error("Comment cannot be empty.");
        return;
      }
      if (newComment.trim().length < 3) {
        toast.error("Comment must be at least 3 characters long.");
        return;
      }
      const response = await fetch(`${apiUrl}/posts/comment`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: loggedInUserId,
          postId: postId,
          comment: newComment,
        }),
      });
      const updatedPost = await response.json();
      fetchPostById(postId);
      if (!response.ok) {
        throw new Error("Failed to upload comment");
      }
      toast.success("Comment added successfully!");
      setNewComment("");
      setCurrentPage(1);
      setReload((prev) => !prev);
      if (commentSectionRef.current) {
        commentSectionRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (error) {
      toast.error(error.message || "Failed to upload comment");
    }
  };
  const fetchPostById = async (postId) => {
    try {
      const response = await fetch(`${apiUrl}/posts/${postId}/post`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const updatedPost = await response.json();
      dispatch(setPost({ post: updatedPost }));
      if (!response.ok) {
        throw new Error("Failed to upload comment");
      }
    } catch (error) {
      toast.error(error.message || "Failed to upload comment");
    }
  };

  const patchLike = async () => {
    try {
      const response = await fetch(`${apiUrl}/posts/${postId}/like`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId: loggedInUserId }),
      });
      const updatedPost = await response.json();
      console.log("like", updatedPost);
      dispatch(setPost({ post: updatedPost }));
      if (!response.ok) {
        throw new Error("Failed to update like status");
      }
    } catch (error) {
      toast.error(
        error.message || "An error occurred while updating the like status"
      );
    }
  };

  const isEventExpired = async () => {
    const eventDay = dayjs(eventDate);
    const currentDay = dayjs();
    setEventExpired(eventDay.isBefore(currentDay));
  };

  useEffect(() => {
    isEventExpired();
  }, [eventDate]);

  useEffect(() => {
    if (isComments) {
      getComments(currentPage, postId); // Fetch comments for the current page
    }
  }, [isComments, currentPage, reload]);

  const getClippedText = (text) => {
    if (!text) return text;
    const limit = isNonMobileScreens ? 180 : 190;
    if (text.length > limit) {
      return viewMore ? (
        <>
          <span>{text}</span>
          <span
            className="cursor-pointer text-primary"
            onClick={() => setViewMore(false)}
          >
            <b> view less</b>
          </span>
        </>
      ) : (
        <>
          <span>{text.substring(0, limit)}</span>
          <span
            className="cursor-pointer text-primary"
            onClick={() => setViewMore(true)}
          >
            <b> ...view more</b>
          </span>
        </>
      );
    }
    return text;
  };

  return (
    <div className="bg-[#3A3939] p-4 rounded-lg mb-4">
      <Friend
        friendId={postUserId}
        name={name}
        subtitle={location}
        userPicturePath={userPicturePath}
        postType={postType}
        postId={postId}
        loggedInUserId={loggedInUserId}
        eventDate={eventDate}
      />

      {(bannerPath || picturePath) && (
        <div className="w-full sm:h-72 rounded-lg bg-[#151515]">
        <img
          src={picturePath || bannerPath}
          alt="Post"
          className="mt-3 w-full sm:h-72 object-contain rounded-lg sm:hidden"
        />
        <img
          src={bannerPath || picturePath}
          alt="Post"
          className="mt-3 w-full sm:h-72 object-contain rounded-lg hidden sm:block"
        />
      </div>
      )}

      <div className="mt-4 px-4 flex justify-between items-center">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1">
            <Typography>{likeCount}</Typography>
            <IconButton onClick={patchLike}>
              {isLiked ? (
                <FavoriteOutlined sx={{ color: primary }} />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
          </div>
          <div className="flex items-center gap-1">
            <Typography>{commentsCount}</Typography>
            <IconButton onClick={handleComment}>
              <ChatBubbleOutlineOutlined />
            </IconButton>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          className="mt-4 hover:text-yellow border border-yellow"
          onClick={() => navigate(`/event/${postId}`)}
        >
          {(eventType === "GUESTLIST" || eventType === "PACKAGE") && eventExpired === false ? "Book Now" : "View Details"}
        </Button>
      </div>

      {isComments && (
        <div className="mt-4 px-2">
          <div
            ref={commentSectionRef}
            className="max-h-28 overflow-y-auto scrollbar-thin "
          >
            {comments.map((comment, i) => (
              <div
                key={comment.id || `comment-${i}`}
                className="flex items-start gap-3 mb-4"
              >
                <img
                  src={comment?.userDetails?.picturePath || DefaultProfilePic}
                  alt="Comment User"
                  className="w-10 h-10 rounded-full"
                />
                <div className="flex-1">
                  <div className="flex gap-2">
                    <Typography className="font-semibold text-white">
                      {comment?.userDetails?.fullName}
                    </Typography>
                    <Typography variant="caption" className="text-gray-400">
                      {formatTimeAgo(comment?.createdAt)}
                    </Typography>
                  </div>
                  <Typography className="text-white mt-2">
                    {comment?.comment}
                  </Typography>
                </div>
              </div>
            ))}
            {loadingComments && (
              <div className="my-4">
                <CircularProgress color="primary" size={24} />
              </div>
            )}
            {hasMoreComments && (
              <div className="mb-4">
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleLoadMore}
                  disabled={!hasMoreComments}
                >
                  Load More
                </Button>
              </div>
            )}
          </div>

          <div className="mb-4 flex items-center gap-2">
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Write a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              className="text-white rounded-lg"
            />
            <IconButton
              onClick={handleSubmitComment}
              className="bg-primary text-white rounded-full p-2"
            >
              <SendIcon />
            </IconButton>
          </div>
        </div>
      )}

      <div className="mt-3">
        <Typography className="text-white">
          {getClippedText(description)}
        </Typography>
      </div>
    </div>
  );
};

function formatTimeAgo(createdAt) {
  const now = new Date();
  const commentDate = new Date(createdAt);
  const diffInMs = now - commentDate;
  const diffInMinutes = Math.floor(diffInMs / 60000);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays >= 1) {
    return `${diffInDays}d`;
  } else if (diffInHours >= 1) {
    return `${diffInHours}h`;
  } else if (diffInMinutes >= 1) {
    return `${diffInMinutes}m`;
  } else {
    return "Just now";
  }
}

export default PostWidget;
