import { Box, IconButton, Typography, useMediaQuery, MenuList, MenuItem, Grow,Popper,ClickAwayListener,Paper } from "@mui/material";
import { useDispatch,useSelector } from "react-redux";
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LocalActivityRoundedIcon from '@mui/icons-material/LocalActivityRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { setLogout } from "state";
const AppBar = () => {
    const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
    const { _id, picturePath, userType, userVerified } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const returnBorderColor = (type) => {
        let pathName = window.location.pathname.split('/');
        if (pathName.length > 0) {
            if (pathName[pathName.length - 1] == type) {
                return true
            } else if (type == 'profile') {
                if (pathName[pathName.length - 2] == type) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        } else {
            return false
        }

    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event,type) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        switch (type) {
            case 'profile':
                navigate(`/profile/${_id}`)
                setOpen(false);
                break;
            case 'logout':
                dispatch(setLogout())
                navigate(`/`)
                setOpen(false);
                break;
        }


    };
    return (
        <>
            <Box style={{ zIndex: '999', height: '7vh', width: '40vh', position: 'fixed', backgroundColor: 'black', bottom: '5%', right: isNonMobileScreens ? '40%' : '7%', border: '0.5px solid white', borderRadius: '50px' }}>

                <Box style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    height: '100%'
                }}>

                    <IconButton style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: '15%',
                        height: '80%',
                        borderRadius: '100%',
                        backgroundColor: (returnBorderColor('home') == true ? 'white' : 'black'),
                        color: (returnBorderColor('home') == true ? 'black' : 'white')
                    }}
                        onClick={() => navigate("/home")}>
                        <HomeRoundedIcon />
                        <Typography variant="caption" style={{ fontSize: '7px' }}>Home</Typography>
                    </IconButton>

                    <IconButton style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: '15%',
                        height: '80%',
                        borderRadius: '100%',
                        backgroundColor: (returnBorderColor('explore') == true ? 'white' : 'black'),
                        color: (returnBorderColor('explore') == true ? 'black' : 'white')
                    }}
                        onClick={() => {
                            navigate('/explore')
                        }}
                    >
                        <LocalActivityRoundedIcon />
                        <Typography variant="caption" style={{ fontSize: '7px' }}>Events</Typography>
                    </IconButton>
                    <IconButton style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: '15%',
                        height: '80%',
                        borderRadius: '100%',
                        backgroundColor: (returnBorderColor('notification') == true ? 'white' : 'black'),
                        color: (returnBorderColor('notification') == true ? 'black' : 'white')
                    }}>
                        <NotificationsRoundedIcon />
                        <Typography variant="caption" style={{ fontSize: '7px' }}>Notification</Typography>
                    </IconButton>
                    {/* <Select>
                        <MenuItem onClick={() => navigate(`/profile/${_id}`)}>My Profile</MenuItem>
                        <MenuItem onClick={() => navigate(`/profile/${_id}`)}>Logout</MenuItem>
                        </Select> */}
                    <IconButton
                        id="composition-button"
                        ref={anchorRef}
                        aria-controls={open ? 'composition-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleToggle}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: '15%',
                            height: '80%',
                            borderRadius: '100%',
                            backgroundColor: (returnBorderColor('profile') == true ? 'white' : 'black'),
                            color: (returnBorderColor('profile') == true ? 'black' : 'white')
                        }}
                    >
                        <AccountCircleRoundedIcon />
                        <Typography variant="caption" style={{ fontSize: '7px' }}>Profile</Typography>
                    </IconButton>
                
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        placement="top-start"
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                        >
                                            <MenuItem onClick={(e) => handleClose(e,'profile')}>My Profile</MenuItem>
                                            <MenuItem onClick={(e) => handleClose(e,'logout')}>Logout</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>




                </Box>

            </Box>
        </>


    );
};

export default AppBar;
