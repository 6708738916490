import React, { useEffect, useState } from "react";
import { Container, Typography, Grid, Link } from "@mui/material";
import {
    Facebook,
    Instagram,
    Twitter,
} from "@mui/icons-material";
import Logo from "../../assests/logo/Vybez_Logo.png";
import { apiUrl } from "config";

const styles = {
    footerBody: {
        // textAlign: 'center'
    },
    footerLinks: {
        fontFamily: "'Inter' sans-serif"
    }
}
const Footer = () => {
    const [specialEvents, setSpecialEvents] = useState([]);
    const [specialEventFetched, setSpecialEventsStatus] = useState(false);


    useEffect(() => {
        const fetchSpecialEventData = async () => {
            try {
                const response = await fetch(`${apiUrl}/special-events`);
                const data = await response.json();
                if (data.error == false) {
                    setSpecialEvents(data.specialEvents);
                    setSpecialEventsStatus(true)
                } else {
                    console.log("Error fetching Special event data:", data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchSpecialEventData();
    }, []);
    return (

        <div
            style={{
                color: "white",
                padding: "32px",
            }}
        >
            <Container style={{ padding: '5% 0' }}>
                <Typography variant="h2" mb="4rem">
                    VYBEZ: WHERE EVERY BEAT CREATES MEMORIES
                </Typography>
                <Grid container spacing={4} style={styles.footerBody}>
                    <Grid item xs={12} md={3}>
                        <img
                            src={Logo}
                            alt="Logo"
                            style={{ maxWidth: "50%", height: "auto", marginBottom: "20px" }}
                        />
                        <Typography>
                            Vybez, the ultimate platform for those who love to explore new
                            events, venues, and artists.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography
                            variant="h4"
                            className="text-[#E6C100]"
                            style={{ fontWeight: "400", marginBottom: "30px", }}
                        >
                            OFFICE
                        </Typography>
                        <Typography>
                            VYBEZ C/O ANANTALOOP 4th Floor, 9th Cross Rd, 2nd Phase, J. P.
                            Nagar, Bengaluru, Karnataka 560078 help@vybez.in
                        </Typography>

                        <div>
                            <Typography
                                variant="h4"
                                className="text-[#E6C100]"
                                style={{ fontWeight: "400", marginBottom: "10px", marginTop: "20px" }}
                            >
                                Cities
                            </Typography>
                            <ul style={{ listStyle: "none", padding: "0px", textAlign: "left" }}>
                                <li style={{ marginBottom: "10px" }}>
                                    <Link
                                        href="/explore"
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                            fontSize: "16px", // Increase font size
                                            transition: "color 0.3s",
                                            "&:hover": { color: "#ffd700" },

                                        }}
                                    >
                                        Bangalore
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Typography
                            variant="h4"
                            className="text-[#E6C100]"
                            style={{ fontWeight: "400", marginBottom: "20px", }}
                        >
                            Links
                        </Typography>
                        <ul style={{ listStyle: "none", padding: "0px", textAlign: "left" }}>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px", // Increase font size
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    Home
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/privacy-policy"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px",
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    Privacy Policy
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/refund-policy"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px",
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    Refund Policy
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/terms-and-conditions"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px",
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    Terms and Conditions
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/contact"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px", // Increase font size
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    Contact Us
                                </Link>
                            </li>
                            <li>
                                <Link
                                    href="/about-us"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px", // Increase font size
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    About Us
                                </Link>
                            </li>
                        </ul>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Typography
                            variant="h4"
                            className="text-[#E6C100]"
                            style={{ fontWeight: "400", marginBottom: "20px", }}
                        >
                            Popular
                        </Typography>
                        <ul style={{ listStyle: "none", padding: "0px", textAlign: "left" }}>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/venues"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px", // Increase font size
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    Venues
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/artists"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px",
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    Artists
                                </Link>
                            </li>
                            <li style={{ marginBottom: "10px" }}>
                                <Link
                                    href="/blog"
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                        fontSize: "16px",
                                        transition: "color 0.3s",
                                        "&:hover": { color: "#ffd700" },

                                    }}
                                >
                                    Blogs
                                </Link>
                            </li>
                            {specialEventFetched == true && specialEvents.length > 0 ?
                                <>
                                    {specialEvents.map((sEvents, index) => {
                                        return (<li style={{ marginBottom: "10px" }}>
                                            <Link
                                                href={`/special-events/${sEvents.specialEventTagName}/${sEvents?.specialEventName}`}
                                                style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                    fontSize: "16px",
                                                    transition: "color 0.3s",
                                                    "&:hover": { color: "#ffd700" },
    
                                                }}
                                            >
                                                {sEvents.specialEventName}
                                            </Link>
                                        </li>)
                                    })}
                                    
                                </>
                                : <></>}


                        </ul>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Typography variant="h6" className="text-[#E6C100]" style={{ textAlign: 'center', fontWeight: "400", marginBottom: "30px", }}>Social Media</Typography>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                marginTop: "20px",
                            }}
                        >
                            <Link
                                href="https://www.facebook.com/vybezurbanfrolic"
                                aria-label="Facebook"
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "24px",
                                    marginRight: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#ffd700" },

                                }}
                            >
                                <Facebook />
                            </Link>
                            <Link
                                href="https://www.instagram.com/vybezoficial/"
                                aria-label="Instagram"
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "24px",
                                    marginRight: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#ffd700" },

                                }}
                            >
                                <Instagram />
                            </Link>
                            <Link
                                href="https://twitter.com/VybezOficial"
                                aria-label="Twitter"
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                    fontSize: "24px",
                                    marginRight: "10px",
                                    transition: "color 0.3s",
                                    "&:hover": { color: "#ffd700" },

                                }}
                            >
                                <Twitter />
                            </Link>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;
