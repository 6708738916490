import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ErrorSection = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  console.log("user",user)
  const backToHomePage = () => {
    user ? navigate("/home") : navigate("/") ;
  };

  return (
    <div className="flex flex-col items-center justify-center h-[600px] bg-[#2A2A2A]">
      <div className="!text-8xl font-bold mb-4">404</div>
      <h1 className="text-4xl font-bold mb-4">Page Not Found</h1>
      <p className="text-gray-300 mb-8">
        Sorry, the page you're looking for doesn't exist.
      </p>
      <div
        onClick={() => {
          backToHomePage();
        }}
        className="cursor-pointer px-4 py-2 bg-yellow text-black rounded-lg"
      >
        Go to Homepage
      </div>
    </div>
  );
};

export default ErrorSection;
