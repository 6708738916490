import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import image from "../../assests/images/artist.jpg";
import { Stack, Pagination, Typography, CircularProgress } from "@mui/material";
import "../../assests/css/swiper.css";
import ArtistCard from "./artistCardComponent";
import { apiUrl } from "config";


const ArtistSection = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [artists, setArtists] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [totalArtists, setTotalArtists] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    fetchArtists(page);
  }, []);
  
  const fetchArtists = async (page) => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/users/artists/${page}/${limit}`);
      const data = await response.json();
      if(data.error == false){
        setArtists(data.artists);
        setTotalArtists(data.total)
      }else{
        setArtists([]);
        setTotalArtists(0)
      }
     
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally{
      setLoading(false);
    }
  };

  const handleSearch = async (event) => {
    setArtists([]);
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    console.log("searchTerm",searchTerm)
    if(searchTerm == undefined || searchTerm == null || searchTerm == ''){
      fetchArtists(page);
    }else{
      const response = await fetch(`${apiUrl}/users/artists-search/${searchTerm}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
  
      });
      let respData = await response.json();
      let artists = respData.artists || []
      setArtists(artists);
      setTotalArtists(respData.total)
    }
  };


  const handleChange = (event, value) => {
    setPage(value);
    fetchArtists(value);
  };

  const getTotalPages = () => {
    return Math.ceil(totalArtists / limit);
  };


  const navigateToArtistDetail = (artist) => {
    navigate(`/artist-detail/${artist.profileSlug}`);
  };

  return (
    <section className="px-4 sm:px-16 py-4 sm:py-10 mb-20">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
        <div className="text-3xl mb-6 sm:mb-0">Artists</div>
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search by..."
          className="w-full sm:w-1/4 p-4 bg-mainBg border focus:outline-none rounded-lg"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {loading ? (
          <div className="flex justify-center items-center w-full h-full">
            <CircularProgress size={50} />
          </div>
        ) : artists.length > 0 ? (
          <>
            {artists.map((artist, index) => (
              <div
                key={index}
                onClick={() => {
                  navigateToArtistDetail(artist);
                }}
                className="cursor-pointer"
              >
                <ArtistCard artist={artist} />
              </div>
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center h-64">
            <Typography variant="h5" className="text-neutral-600">
              No Artists Found
            </Typography>
          </div>
        )}
      </div>
      <Stack spacing={2} className="mt-8 flex items-center">
        <Pagination
          count={getTotalPages()}
          variant="outlined"
          shape="rounded"
          onChange={handleChange}
          page={page}
        />
      </Stack>
    </section>
  );
};

export default ArtistSection;
